import { Button } from "@/components/ui/button";
import useQuery from "@/hooks/use-query";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";

const CvList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { toast } = useToast();
  const {
    data: cvs,
    loading,
    error,
    meta
  } = useQuery(
    "agent-users",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search
  );

  const [user , setUser] = useState({name : '', email : ''});

  if (error) {
    return "error";
  }
  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };

  const createUser = (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ApiService.post('agent-users' , user).then((res) => {
      toast({
        title: res.data?.message,
        description: res.data?.message,
        variant: "success"
      });
      window.location.reload();
    }).catch((err) => {
      toast({
        title: err.response.data?.message,
        description: err.response.data?.message,
        variant: "destructive"
      });
    })
  }

  return !loading ? (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="flex-grow text-xl font-semibold">Your Person List</h1>
        <Dialog>
          <DialogTrigger asChild>
            <Button>Create New User</Button>
          </DialogTrigger>
          <DialogContent className="bg-white dark:bg-slate-700">
            <DialogHeader>
              <DialogTitle>Create New User</DialogTitle>
              <DialogDescription>
                <form onSubmit={createUser}>
                  <div>
                    <Label>Name</Label>
                    <Input
                      name="name"
                      value={user.name}
                      onChange={(e) => setUser({...user, name : e.target.value})}
                      placeholder="John Doe"
                      className="w-full mt-1"
                    />
                  </div>
                  <div className="mt-4">
                    <Label>Email</Label>
                    <Input
                      name="email"
                      value={user.email}
                      onChange={(e) => setUser({...user, email : e.target.value})}                    
                      type="email"
                      placeholder="example@gmail.com"
                      className="w-full mt-1"
                    />
                  </div>
                  <div className="flex justify-end mt-4">
                    <Button type="submit">
                      Create
                    </Button>
                  </div>
                </form>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <div className="grid grid-cols-8 p-3 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
          <div>ID</div>
          <div>Name</div>
          <div>Email</div>
          <div>Gender</div>
          <div>State</div>
          <div>Nationality</div>
          <div>Visa Type</div>
          <div>Actions</div>
        </div>
        {cvs.length ? (
          cvs.map((cv) => (
            <div
              key={cv.id}
              className="grid items-center grid-cols-8 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
            >
              <div>{cv.id}</div>
              <div>{cv.name}</div>
              <div>{cv.email}</div>
              <div>{cv.gender}</div>
              <div>{cv.state?.name}</div>
              <div>{cv.nation}</div>
              <div>{cv.visa_type}</div>
              <div>
                <Button
                  asChild
                  className="border bg-background dark:bg-foreground border-background text-slate-800"
                >
                  <Link to={`/cv-list/${cv.id}`}>View Details</Link>
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center py-12">
            <p>No data.</p>
          </div>
        )}
      </div>

      <div className="flex justify-center gap-2 mt-6">
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page < 2}
          onClick={() => {
            paginate(meta.current_page - 1, meta.per_page);
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page >= meta.last_page}
          onClick={() => {
            paginate(meta.current_page + 1, meta.per_page);
          }}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : (
    <div>loading</div>
  );
};

export default CvList;
