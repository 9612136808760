import { Outlet, NavLink, Link, useNavigate } from "react-router-dom";
import { ThemeToggle } from "@/components/theme/theme-toggle";

import navigations from "./navigations";

import { Button } from "@/components/ui/button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import {
  Plus,
  CircleHelp,
  Bell,
  ChevronDown,
  AlignJustify,
  User,
  Currency,
  Group,
  LogOut,
} from "lucide-react";
import { useState } from "react";

const IndexLayout = () => {
  const navigate = useNavigate();
  const [minibar, setMinibar] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'))
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  }
  return (
    <div className="flex">
      <aside
        style={{
          width: minibar ? "100px" : "360px",
          minWidth: minibar ? "100px" : "360px",
        }}
        onMouseEnter={() => {
          setMinibar(false);
        }}
        onMouseLeave={() => {
          setMinibar(true);
        }}
        className="sticky top-0 hidden h-screen text-white transition-all border-r shadow lg:block bg-slate-800 border-slate-300 dark:border-slate-700"
      >
        <h1 className="flex items-center justify-center gap-3 py-4 text-3xl font-black text-center">
          <img src="/logo.svg" alt="" />
          {!minibar && <span className="transition-all">OOAK JOB</span>}
        </h1>
        <div className="flex justify-center my-8">
          <Button
            variant="link"
            size={minibar ? "icon" : "lg"}
            className={`flex transition-all gap-3 text-lg ${
              minibar
                ? ""
                : "bg-white dark:text-white rounded-full dark:bg-slate-700"
            }`}
          >
            <Link
              className="flex items-center gap-3"
              to="/jobs/create/initial-information"
            >
              <Plus className="w-6 h-6 text-white rounded-full bg-primary" />
              {!minibar && <span>Create New Job</span>}
            </Link>
          </Button>
        </div>
        <ul>
          {navigations.map(({ name, icon, link }) => (
            <li key={name} className="p-2">
              <Button
                variant="link"
                size={minibar ? "icon" : "lg"}
                className="w-full no-underline bg-transparent "
              >
                <NavLink
                  className={`flex items-center gap-3 text-white ${
                    minibar
                      ? "!bg-transparent"
                      : " px-10 w-full h-full  rounded-full hover:bg-white hover:text-primary"
                  }`}
                  to={link}
                >
                  {icon}
                  {!minibar && <span className="transition-all">{name}</span>}
                </NavLink>
              </Button>
            </li>
          ))}
        </ul>
        <Button size="icon" className="absolute rounded-full bottom-8 left-6">
          <CircleHelp />
        </Button>
      </aside>
      <div className="flex-grow">
        <nav className="justify-between sticky top-0 z-50 border-b border-slate-300 dark:border-slate-700 pt-3 p-2 bg-background rounded px-2 flex items-center header h-[60px]">
          <div className="flex items-center gap-3">
            <Sheet>
              <SheetTrigger asChild>
                <Button className="lg:hidden" size="icon" variant="ghost">
                  <AlignJustify />
                </Button>
              </SheetTrigger>
              <SheetContent
                className="dark:text-white bg-slate-800 border-slate-700"
                side="left"
              >
                <SheetHeader>
                  <SheetTitle className="text-3xl font-black text-white">
                    OOAK JOB
                  </SheetTitle>
                  <SheetDescription></SheetDescription>
                </SheetHeader>
                <div className="flex justify-center my-8">
                  <Button
                    variant="secondary"
                    size="lg"
                    className="text-xl bg-white rounded-full dark:bg-slate-700"
                  >
                    <Link
                      className="flex items-center gap-3"
                      to="/jobs/create/initial-informations"
                    >
                      <Plus className="w-8 h-8 text-white rounded-full bg-primary" />
                      <span>Create New Job</span>
                    </Link>
                  </Button>
                </div>
                <ul>
                  {navigations.map(({ name, icon, link }) => (
                    <li key={name} className="p-2">
                      <SheetClose asChild>
                        <Button asChild type="submit" variant="link">
                          <NavLink
                            className="flex items-center justify-start w-full h-full gap-3 py-4 text-white rounded-full hover:bg-white hover:text-primary"
                            to={link}
                          >
                            {icon}
                            <span className="flex-grow">{name}</span>
                          </NavLink>
                        </Button>
                      </SheetClose>
                    </li>
                  ))}
                </ul>
                <Button
                  size="icon"
                  className="absolute rounded-full bottom-8 left-8"
                >
                  <CircleHelp />
                </Button>
              </SheetContent>
            </Sheet>
            <h1 className="hidden text-xl font-bold md:block">Dashboard</h1>
          </div>
          <div className="flex items-center gap-3">
            <Button
              size="icon"
              variant="ghost"
              className="px-3 bg-white rounded-full dark:bg-slate-300 dark:text-slate-800"
            >
              <Bell />
            </Button>
            <ThemeToggle />
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center gap-3 min-w-[14rem] px-4 py-1 bg-white rounded-full w-fit dark:text-slate-800 dark:bg-slate-300">
                <img src="/vite.svg" className="w-6 h-6" alt="User Profile" />
                <div className="flex-grow w-fit">
                  <h3 className="text-xs font-semibold text-start">
                    {user?.name}
                  </h3>
                  <h5 className="text-xs text-start">Agent ID - 12097</h5>
                </div>
                <ChevronDown className="w-6 h-6" />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="min-w-[14rem]">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem className="flex items-center gap-2 cursor-pointer">
                  <User />
                  <span>Profile</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex items-center gap-2 cursor-pointer">
                  <Currency />
                  <span>Billing</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex items-center gap-2 cursor-pointer">
                  <Group />
                  <span>Team</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex items-center gap-2 cursor-pointer">
                  <Bell />
                  <span>Subscription</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="flex items-center gap-2 cursor-pointer" onClick={logout}>
                  <LogOut />
                  <span>Logout</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </nav>
        <main className="z-10 min-h-screen p-2 bg-background main">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default IndexLayout;
