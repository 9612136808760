import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Calendar } from "../ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover";
import { Button } from "./button";

type Props = {
  date: Date;
  emitDate: (value : Date) => void;
  disabled ?: boolean
};
const DatePicker = ({ date, emitDate , disabled = false } : Props) => {
    const handleSelect = (e : Date) => {
      emitDate(e)
    }
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          disabled={disabled}
          className={cn(
            " justify-start mt-1 w-full text-left bg-transparent dark:bg-slate-300 dark:text-slate-700 bg-slate-100 border-0 font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon />
          {date ? format(date, "PPP") : <span>Select a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={new Date(date)}
          onSelect={handleSelect}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;