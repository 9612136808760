import Appointments from "@/components/job-applications/Appointments";
import ProcessInfo from "@/components/job-applications/ProcessInfo";
import UserDetails from "@/components/job-applications/UserDetails";
import { Button } from "@/components/ui/button";
import useQuery from "@/hooks/use-query";
import {
  ChevronLeft,
  FileText,
  Folder,
  IdCard,
  Info,
  MessageSquare,
  Users,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ApplicationDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [searchParams , setSearchParams] = useSearchParams();
   //if coming from applied list, there will be agent type on parameters.
   //used wherther from an agent viewing the application he applied
   //or the reciever of the application viewing
  const viewType = searchParams.get('type');

  const id = params.id ? params.id : "";
  const [tab, setTab] = useState<string>(
    searchParams.get('tab') ?? "cv"
  );

  useEffect(() => {
    setSearchParams({tab : tab , type : viewType ?? ''} , {replace : true})
  } , [tab , setSearchParams , viewType])

  const { data: application ,loading , error } = useQuery(`job-applications/${id}`);
  if (error) {
    return "error";
  }

  return !loading ? (
    <div>
      <div className="items-center justify-between lg:flex">
        <div className="flex flex-wrap items-center flex-grow gap-4 mb-4">
          <Button onClick={() => navigate(-1)} size="icon" className="rounded-full">
            <ChevronLeft />
          </Button>
          <Button
            onClick={() => setTab("cv")}
            variant={tab == "cv" ? "default" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <FileText className="w-4 h-4" />
            <span>CV Info</span>
          </Button>
          <Button
            onClick={() => setTab("process")}
            variant={tab == "process" ? "default" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <Info className="w-4 h-4" />
            <span>Process Info</span>
          </Button>
          <Button
            onClick={() => setTab("appointment")}
            variant={tab == "appointment" ? "default" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <Users className="w-4 h-4" />
            <span>Appointment</span>
          </Button>
          <Button
            onClick={() => setTab("files")}
            variant={tab == "files" ? "default" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <Folder className="w-4 h-4" />
            <span>File Attachments</span>
          </Button>
          {viewType == 'agent' && <Button
            onClick={() => setTab("agent")}
            variant={tab == "agent" ? "default" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <IdCard className="w-4 h-4" />
            <span>Agent Info</span>
          </Button>}
        </div>
        <div>
          <Button
            variant="default"
            className="flex items-center gap-2 rounded-full"
          >
            <MessageSquare className="w-4 h-4" />
            <span>Chat</span>
          </Button>
        </div>
      </div>
      {tab == "cv" ? (
        <UserDetails application={application} />
      ) : tab == "process" ? (
        <ProcessInfo application={application} />
      ) : tab == "appointment" ? (
        <Appointments application={application} />
      ) : tab == "files" ? (
        "files"
      ) : (
        "agent info"
      )}
    </div>
  ) : (
    <p>loading</p>
  );
};

export default ApplicationDetails;
