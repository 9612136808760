import { Job } from "@/types";
import { BadgeJapaneseYen, Banknote, Bookmark, BriefcaseBusiness, Clock, Languages, MapPin } from "lucide-react";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";

const JobCard = ({job , className} : {job : Job , className ?: string}) => {
    const navigate = useNavigate();
  const { toast } = useToast();

    const saveJob = () => {
      ApiService.post(`jobs/${job.id}/save` , {}).then((res) => {
        console.log(res)
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
      }).catch((err) => {
        console.log(err)
        toast({
          title: "Error saving job.",
          description: err.response.data?.message,
          variant: "destructive",
        });
      })
    }
    return ( 
        <li
        key={job.id}
        className={`p-3 list-none bg-white rounded-lg dark:bg-slate-600 ${className}`}
      >
          <div className="flex gap-2">
            <img
             onClick={() => navigate(`/shared-jobs/${job.id}`)}
              src={job.image?.url}
              className="w-[75px] min-w-[75px] cursor-pointer h-[75px] rounded-lg object-cover"
              alt=""
            />
            <div onClick={() => navigate(`/shared-jobs/${job.id}`)} className="flex-grow cursor-pointer">
              <h3 className="text-lg line-clamp-2">{job.title}</h3>
              <h4 className="my-1">{job.agent?.name}</h4>
              <h6 className="flex items-center gap-1 text-sm">
                <MapPin className="w-4 h-4" />
                <span className="line-clamp-2">{job.address}</span>
              </h6>
            </div>
            <Button onClick={saveJob} className={`${job.is_saved ? 'text-primary' : ''}`} size="icon" variant="ghost">
              <Bookmark />
            </Button>
          </div>
          <div onClick={() => navigate(`/shared-jobs/${job.id}`)} className="flex gap-2 mt-4 cursor-pointer">
            <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
              <BriefcaseBusiness />
              <span>{job.employee_type?.name}</span>
            </div>
            <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
              <Languages />
              <span>{job.jp_language_require ? "Yes" : "No"}</span>
            </div>
            <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
              <BriefcaseBusiness />
              <span>{job.pay_scale}</span>
            </div>
          </div>
          <div onClick={() => navigate(`/shared-jobs/${job.id}`)} className="flex items-center gap-1 px-4 py-2 my-4 text-sm rounded-full cursor-pointer w-fit h-fit bg-slate-200 dark:bg-slate-500">
            <BadgeJapaneseYen />
            <span>
              {/* {job.min_salary}¥ to {job.max_salary}¥ / per{" "}
              {job.show_pay_by} */}
              {job.salary_range}
            </span>
          </div>
          <hr />
          <div onClick={() => navigate(`/shared-jobs/${job.id}`)} className="flex justify-between gap-4 mt-4 text-sm cursor-pointer">
            <p className="flex items-center gap-1">
              <Banknote className="opacity-80" />
              <span>Agent Fee - {job.min_salary}</span>
            </p>
            <p className="flex items-center gap-1">
              <Clock className="opacity-80" />
              <span>{job.created_at}</span>
            </p>
          </div>
      </li>
     );
}
 
export default JobCard;