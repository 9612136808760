import { Jobs } from "@/types";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Users } from "lucide-react";

const GridView = ({ jobs }: { jobs: Jobs }) => {
  return (
    <ul className="grid grid-cols-1 gap-4 pt-4 md:grid-cols-2 lg:grid-cols-4">
      {jobs.map((job) => (
        <li
          key={job.id}
          className="p-2 bg-white rounded-lg dark:bg-background dark:border border-slate-500"
        >
          <img
            className="w-[180px] h-[180px] mx-auto rounded"
            src="/logo.svg"
            alt=""
          />
          <h3 className="col-span-2 my-2 text-xl font-semibold">{job.title}</h3>
          <p className="flex items-center gap-2"><Users /> - {job?.applied_users?.length}</p>
          <p className="my-2">{job.created_at.slice(0,10)}</p>
          <Button
            asChild
            size="lg"
            className="w-full border bg-background dark:bg-foreground border-background text-slate-800"
          >
            <Link to={`/jobs/${job.id}?tab=info`}>View Details</Link>
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default GridView;
