import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { Button } from "@/components/ui/button";

const Home = () => {
  const { toast } = useToast();
  return (
    <div>
      <Button
        onClick={() =>
          toast({
            title: "Scheduled: Catch up",
            description: "Friday, February 10, 2023 at 5:57 PM",
            variant: "success",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          })
        }
      >
        Hello Home
      </Button>
    </div>
  );
};

export default Home;
