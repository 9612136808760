import { useEffect, useState } from "react";

import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Building, ChevronRight, User } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";

// This page was added after the whole process of creating job.
// That's why it is out of the jobcontext that was created before.
// Don't be judgmental.

const InitialInfo = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [initials, setInitials] = useState({
    type: "agent",
    share: "yes",
    image_id: 0,
    image: {
      id: 0,
      url: ""
    }
  });
  const [loading, setLoading] = useState(false);

  const uploadPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) {
      toast({
        title: "Error uploading image.",
        description: "Please try again.",
        variant: "destructive"
      });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.set("file", file);
      ApiService.post(`images`, formData, {
        "Content-Type": "multipart/form-data"
      })
        .then((res) => {
          toast({
            title: res.data.message,
            description: res.data.message,
            variant: "success"
          });
          setLoading(false);
          setInitials({
            ...initials,
            image_id: res.data.data.id,
            image: res.data.data
          });
        })
        .catch((err) => {
          setLoading(false);
          toast({
            title: err.response.data?.message,
            description: err.response.data?.errors?.file,
            variant: "destructive"
          });
        });
    }
  };

  const handleSubmit = () => {
    const str = JSON.stringify(initials);
    sessionStorage.setItem("initials", str);
    navigate("/jobs/create");
  };

  useEffect(() => {
    const ini = sessionStorage.getItem('initials');
    if (ini) {
      setInitials(JSON.parse(ini))
    }
  } , [setInitials])

  return (
    <div className="grid-cols-4 gap-3 lg:grid">
      <div className="col-span-3">
        <div className="p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
          <h2 className="text-xl">
            Fill in the information to post a staff call.
          </h2>
        </div>
        <div className="p-3 bg-white rounded-lg dark:bg-slate-700">
          <h2 className="text-xl font-semibold">Select One</h2>
          <p className="my-2">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
            accusantium aliquam optio quo consequuntur deleniti totam{" "}
          </p>
          <RadioGroup
            value={initials.type}
            onValueChange={(e) => setInitials({ ...initials, type: e })}
            defaultValue={initials.type}
          >
            <div
              onClick={() => setInitials({ ...initials, type: "agent" })}
              className={`flex items-center justify-between my-3 p-4 border rounded-lg ${
                initials.type == "agent"
                  ? "border-primary"
                  : "dark:border-slate-300"
              }`}
            >
              <Label className="flex items-center gap-2" htmlFor="agent">
                <User />
                <span className="text-lg font-bold">Agent</span>
              </Label>
              <RadioGroupItem value="agent" id="agent" />
            </div>
            <div
              onClick={() => setInitials({ ...initials, type: "company" })}
              className={`flex items-center justify-between p-4 border rounded-lg ${
                initials.type == "company"
                  ? "border-primary"
                  : "dark:border-slate-300"
              }`}
            >
              <Label className="flex items-center gap-2" htmlFor="company">
                <Building />
                <span className="text-lg font-bold">Company</span>
              </Label>
              <RadioGroupItem value="company" id="company" />
            </div>
          </RadioGroup>
          <h2 className="mt-8 text-xl font-semibold">Share Agent</h2>
          <p className="my-2">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
            accusantium aliquam optio quo consequuntur deleniti totam{" "}
          </p>
          <RadioGroup
            value={initials.share}
            onValueChange={(e) => setInitials({ ...initials, share: e })}
            defaultValue={initials.share}
            className="flex gap-4 mt-2"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="yes" id="yes" />
              <Label className="text-lg" htmlFor="yes">
                Yes
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="no" id="no" />
              <Label className="text-lg" htmlFor="no">
                No
              </Label>
            </div>
          </RadioGroup>
          <div className="flex justify-end my-8">
            <Button
              onClick={handleSubmit}
              className="flex items-center gap-2 rounded-full"
            >
              <span>Next</span>
              <ChevronRight />
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center p-3 mt-6 bg-white rounded-lg lg:mt-0 dark:bg-slate-700">
        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 rounded-lg cursor-pointer bg-slate-50 dark:bg-slate-600 hover:bg-slate-100 dark:hover:bg-slate-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-slate-500 dark:text-slate-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2s text-slate-500 dark:text-slate-300">
                Job Image
              </p>
              <p className="mb-2 text-sm text-slate-500 dark:text-slate-300">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-slate-500 dark:text-slate-300">
                SVG, PNG, JPG or GIF
              </p>
            </div>
            <input
              id="dropzone-file"
              onChange={uploadPhoto}
              type="file"
              className="hidden"
            />
          </label>
        </div>
        {initials.image_id && initials.image.url ? (
          <img
            className="w-full mt-4 rounded-lg max-w-[400px] mx-auto h-fit"
            src={initials.image.url}
            alt="Job Image"
          />
        ) : null}
        {loading ? (
          <p className="mt-2 text-destructive">Loading . . .</p>
        ) : null}
      </div>
    </div>
  );
};

export default InitialInfo;
