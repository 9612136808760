import StepOne from "@/components/jobs/create/StepOne";
import StepTwo from "@/components/jobs/create/StepTwo";
import StepThree from "@/components/jobs/create/StepThree";
import StepFour from "@/components/jobs/create/StepFour";
import StepFive from "@/components/jobs/create/StepFive";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useContext, useEffect, useState } from "react";
import { JobContext } from "@/contexts/JobContext";
import { Job } from "@/types";

const Steps = () => {
  const [tab, setTab] = useState<string>("1");
  const jobContext = useContext(JobContext);
  if (!jobContext) {
    throw new Error("JobContext must be used within a JobProvider");
  }
  const { job: oldData, dispatch } = jobContext;
  const [job, setJobState] = useState<Job>(oldData);

  const setJob = (j : Job) => {
    setJobState(j);
    dispatch({ type: "Add_FIELDS", payload: j })
  }

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    nextTab: string,
  ) => {
    e.preventDefault();
    dispatch({ type: "Add_FIELDS", payload: job });
    const str = JSON.stringify(job);
    sessionStorage.setItem("job", str);
    setTab(nextTab);
  };

  useEffect(() => {
    if (JSON.stringify(job.errors) !== JSON.stringify(oldData.errors)) {
      setJob({
        ...job,
        errors: { ...job.errors, ...oldData.errors },
      });
    }
  }, [oldData.errors]);

  return (
    <Tabs value={tab} defaultValue="1" className="w-full">
      <TabsList className="w-full bg-transparent">
        <TabsTrigger onClick={() => setTab('1')} className="mx-1 rounded-full" value="1">
          Step 1
        </TabsTrigger>
        <TabsTrigger onClick={() => setTab('2')} className="mx-1 rounded-full" value="2">
          Step 2
        </TabsTrigger>
        <TabsTrigger onClick={() => setTab('3')} className="mx-1 rounded-full" value="3">
          Step 3
        </TabsTrigger>
        <TabsTrigger onClick={() => setTab('4')} className="mx-1 rounded-full" value="4">
          Step 4
        </TabsTrigger>
        <TabsTrigger onClick={() => setTab('5')} className="mx-1 rounded-full" value="5">
          Step 5
        </TabsTrigger>
      </TabsList>
      <TabsContent value="1">
        <StepOne job={job} setJob={setJob} handleSubmit={handleSubmit} />
      </TabsContent>
      <TabsContent value="2">
        <StepTwo
          setTab={setTab}
          job={job}
          setJob={setJob}
          handleSubmit={handleSubmit}
        />
      </TabsContent>
      <TabsContent value="3">
        <StepThree
          setTab={setTab}
          job={job}
          setJob={setJob}
          handleSubmit={handleSubmit}
        />
      </TabsContent>
      <TabsContent value="4">
        <StepFour
          setTab={setTab}
          job={job}
          setJob={setJob}
          handleSubmit={handleSubmit}
        />
      </TabsContent>
      <TabsContent value="5">
        <StepFive
          setTab={setTab}
          job={job}
          setJob={setJob}
          handleSubmit={handleSubmit}
        />
      </TabsContent>
    </Tabs>
  );
};

export default Steps;
