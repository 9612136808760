import { CvForm } from "@/types";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { CalendarIcon, ChevronLeft, ChevronRight, Plus, Trash } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@radix-ui/react-popover";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import { MonthPicker } from "../ui/month-picker";
type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  cv: CvForm;
  setCv: React.Dispatch<React.SetStateAction<CvForm>>;
  errors: {
    [key: string]: string;
  };
};

const FormCertificates = ({ setTab, cv, setCv, errors }: Props) => {
  const setArrayData = (index: number, key: string, value: string) => {
    if (key == "date") {
      const certificatesArray = cv.certificates.map((certificate, i) => {
        if (i == index) {
          certificate.month = new Date(value).getMonth() + 1;
          certificate.year = new Date(value).getFullYear();
          certificate.date = new Date(value);
          console.log(certificate)
        }
        return certificate;
      });
      setCv({ ...cv, certificates: certificatesArray });
    } else {
      const certificatesArray = cv.certificates.map((certificate, i) => {
        if (i == index) {
          certificate[key] = value;
        }
        return certificate;
      });
      setCv({ ...cv, certificates: certificatesArray });
    }
  };
  const addMorecertificate = () => {
    const obj = {
      name: "",
      year: 2024,
      month: 1,
      date: new Date()
    };
    setCv({ ...cv, certificates: [...cv.certificates, obj] });
  };

  const removecertificate = (index: number) => {
    const certificatesArray = cv.certificates.filter((_, i) => i !== index);
    setCv({ ...cv, certificates: certificatesArray });
  };

  return (
    <div>
      <h1 className="text-xl">Certificates</h1>
      <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
        {errors["certificates.0.name"] ? (
          <p className="text-destructive">• {errors["certificates.0.name"]}</p>
        ) : null}
        {errors["certificates.0.month"] || errors["certificates.0.year"] ? (
          <p className="text-destructive">• Date は、必ず指定してください。</p>
        ) : null}
      </div>
      {cv.certificates?.map((certificate, i) => (
        <div
          key={i}
          className="grid grid-cols-1 gap-4 p-2 mt-4 border rounded-lg dark:border-slate-500 md:grid-cols-2"
        >
          <div className="flex items-center justify-between md:col-span-2">
            <h2 className="text-lg">• Certificate {i + 1}</h2>
            <Button
              onClick={() => removecertificate(i)}
              size="icon"
              variant="secondary"
            >
              <Trash />
            </Button>
          </div>
          <div>
            <Label>Certificate Name</Label>
            <Input
              value={cv.certificates[i]?.name}
              onChange={(e) => setArrayData(i, "name", e.target.value)}
              className="mt-1"
              placeholder="certificate name"
            />
          </div>
          <div>
            <Label>Certified Date</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full border-0 mt-1 justify-start bg-slate-100 dark:bg-slate-300 dark:text-slate-700  text-left font-normal",
                    !cv.certificates[i]?.date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="w-4 h-4 mr-2" />
                  {cv.certificates[i]?.date ? format(cv.certificates[i]?.date, "MMM yyyy") : <span>Pick a month</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0 bg-white dark:bg-slate-700 dark:text-slate-300">
                <MonthPicker
                  onMonthSelect={(e : Date) => setArrayData(i , "date" , e.toString())}
                  selectedMonth={cv.certificates[i]?.date}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      ))}
      <div className="flex justify-center mt-4">
        <Button
          onClick={addMorecertificate}
          className="flex items-center gap-1"
          variant="secondary"
        >
          <Plus />
          <span>Add More Certiificate</span>
        </Button>
      </div>
      <div className="flex justify-between mt-8 md:col-span-2">
        <Button
          onClick={() => setTab("edu")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <ChevronLeft />
          <span>Previous</span>
        </Button>
        <Button
          onClick={() => setTab("skills")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default FormCertificates;
