import { Button } from "@/components/ui/button";
import { JobContext } from "@/contexts/JobContext";
import { useContext } from "react";
import parse from "html-react-parser";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
const JobDetail = () => {
  const navigate = useNavigate();
  const jobContext = useContext(JobContext);
  if (!jobContext) {
    throw new Error("JobContext must be used within a JobProvider");
  }
  const { job, dispatch } = jobContext;

  const { toast } = useToast();
  const user = JSON.parse(localStorage.getItem('user'))
  const iniStr = sessionStorage.getItem('initials')
  let initials = {
    share : 'no',
    type : 'agent',
    image_id : 0,
    image : {
      id : 0,
      url : ''
    }
  };
  if(iniStr) {
    initials = JSON.parse(iniStr)
  }
  const uploadJob = () => {
    console.log(job);
    const obj = {
      has_shared : initials.share == 'yes',
      type : initials.type,
      image_id : initials.image_id,
      address : job.address,
      anyone : job.anyone,
      bonus : job.bonus,
      bonus_explain : job.bonus_explain,
      category_id : job.category_id,
      description : job.description,
      employment_type_id : job.employment_type_id,
      housing_support : job.housing_support,
      housing_explain : job.housing_explain,
      min_salary : job.min_salary,
      max_salary : job.max_salary,
      off_days_per_year : job.off_days_per_year,
      off_days_explain : job.off_days_explain,
      pay_scale : job.pay_scale,
      publish : job.publish,
      requirements : job.requirements,
      show_pay_by : job.show_pay_by,
      state_id : job.state_id,
      sub_category_id : job.sub_category_id,
      title : job.title,
      trial_deadline : job.trial_deadline,
      working_hour : job.working_hour,
      working_hour_per : job.working_hour_per,
      yearly_salary : job.yearly_salary,
    }
    ApiService.post("jobs", obj)
      .then((res) => {
        sessionStorage.removeItem('job');
        sessionStorage.removeItem('initials');
        navigate("/jobs");
        console.log(res);
      })
      .catch((err) => {
        console.log(err?.response?.data?.errors);
        dispatch({ type: "ERROR", payload: err?.response?.data?.errors });
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };

  return (
    <div className="relative pb-12 min-h-[80vh]">
      <div className="grid grid-cols-4 gap-x-3 gap-y-1">
        <img
          className="row-span-3 rounded"
          src={initials.image?.url}
          alt=""
        />
        <h3 className="col-span-3 text-xl font-semibold h-fit">{job.title}</h3>
        <p className="col-span-3 font-semibold h-fit">{user?.name}</p>
        <p className="col-span-3 h-fit">Agent ID - {user?.agent_id}</p>
      </div>
      <div className="my-6">
        <h3 className="mb-1 text-xl font-semibold">Salary Range</h3>
        <p>{job.min_salary} - {job.max_salary} / yearly</p>
      </div>
      <div className="my-6">
        <h3 className="mb-1 text-xl font-semibold">Job Description</h3>
        <div className="max-h-[25vh] paragraph overflow-scroll hide-scroll">
          {job.description ? parse(job.description) : "-"}
        </div>
      </div>
      <div className="my-6">
        <h3 className="mb-1 text-xl font-semibold">Requirements</h3>
        <div className="max-h-[25vh] paragraph overflow-scroll hide-scroll">
          {job.description ? parse(job.requirements) : "-"}
        </div>
      </div>
      <div className="absolute w-full bottom-4">
        <Button
          onClick={uploadJob}
          size="lg"
          className="w-full font-bold rounded-full"
        >
          Post Now
        </Button>
      </div>
    </div>
  );
};

export default JobDetail;
