import { createBrowserRouter } from "react-router-dom";
import IndexLayout from "@/layouts";
import ProtectedRoute from "./ProtectedRoute";
import Home from "@/pages/Home";

import Login from "@/pages/auth/Login";

import JobsView from "@/pages/jobs/JobsView";
import InitialInfo from "@/pages/jobs/InitialInfo";
import CreateJob from "@/pages/jobs/CreateJob";
import JobDetails from "@/pages/jobs/JobDetails";

import ApplicationsView from "@/pages/job-applications/ApplicationsView";
import ApplicationDetails from "@/pages/job-applications/ApplicationDetails";
import SharedJobs from "@/pages/shared-jobs/SharedJobs";
import SharedJobDetails from "@/pages/shared-jobs/SharedJobDetails";
import SavedJobs from "@/pages/saved-jobs/SavedJobs";
import AppliedList from "@/pages/applied-list/AppliedList";
import CvList from "@/pages/cv-list/CvList";
import CvDetails from "@/pages/cv-list/CvDetails";
import EditCv from "@/pages/cv-list/EditCv";

const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <IndexLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "jobs",
        element: <JobsView />,
      },
      {
        path: "jobs/create",
        element: <CreateJob />,
      },
      {
        path: "jobs/create/initial-information",
        element: <InitialInfo />,
      },
      {
        path: "jobs/:id",
        element: <JobDetails />,
      },
      {
        path: "job-applications",
        element: <ApplicationsView />,
      },
      {
        path: "job-applications/:id",
        element: <ApplicationDetails />,
      },
      {
        path: "shared-jobs",
        element: <SharedJobs />,
      },
      {
        path: "shared-jobs/:id",
        element: <SharedJobDetails />,
      },
      {
        path: "saved-jobs",
        element: <SavedJobs />,
      },
      {
        path: "applied-list",
        element: <AppliedList />,
      },
      {
        path: "cv-list",
        element: <CvList />,
      },
      {
        path: "cv-list/:id",
        element: <CvDetails />,
      },
      {
        path: "cv-list/:id/cv-forms/:cv",
        element: <EditCv />,
      },
      {
        path: "contact/:id",
        element: (
          <ProtectedRoute>
            <div>Contact</div>
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;
