import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import JoditEditor from "jodit-react";

import { useRef, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Job } from "@/types";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
};

const StepThree = ({ setTab, job, setJob, handleSubmit }: Props) => {
  const editor = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ];
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter text here...",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
    }),
    [],
  );

  return (
    <form
      onSubmit={(e) => handleSubmit(e, "4")}
      className="max-h-[80vh] overflow-y-auto"
    >
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Job Hours</h2>
        <p className="my-2">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          accusantium aliquam optio quo consequuntur deleniti totam{" "}
        </p>
      </div>
      <div className="grid grid-cols-3 gap-3 mb-6">
        <div className="col-span-2">
          <Label htmlFor="postal_code">Hours *</Label>
          <Input
            value={job.working_hour}
            onChange={(e) => {
              setJob({ ...job, working_hour: e.target.value });
            }}
            className="mt-1"
            type="number"
            placeholder="00:00 hrs"
          />
          {job.errors?.working_hour && (
            <span className="text-destructive">{job.errors.working_hour}</span>
          )}
        </div>
        <div>
          <Label htmlFor="working_hour_per">Rate *</Label>
          <Select
            value={job.working_hour_per}
            onValueChange={(e) => {
              setJob({ ...job, working_hour_per: e });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Monthly" />
            </SelectTrigger>
            <SelectContent defaultValue="monthly">
              <SelectItem value="monthly">Monthly</SelectItem>
              <SelectItem value="daily">Daily</SelectItem>
              <SelectItem value="weekly">Weekly</SelectItem>
            </SelectContent>
          </Select>
          {job.errors?.working_hour_per && (
            <span className="text-destructive">
              {job.errors.working_hour_per}
            </span>
          )}
        </div>
      </div>
      <div className="mb-6">
        <p>Overtime</p>
        <RadioGroup
          value={job.overtime ? "1" : "0"}
          onValueChange={(e) => setJob({ ...job, overtime: e == "1" })}
          defaultValue={job.overtime ? "1" : "0"}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="1" id="yes" />
            <Label htmlFor="yes">Yes</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="0" id="no" />
            <Label htmlFor="no">No</Label>
          </div>
        </RadioGroup>
        {job.errors?.overtime && (
          <span className="text-destructive">{job.errors.overtime}</span>
        )}
      </div>
      {job.overtime && <div className="mb-6">
        <Label htmlFor="overtime_per_monthly">Overtime per Month *</Label>
        <Input
          value={job.overtime_per_month}
          onChange={(e) => {
            setJob({ ...job, overtime_per_month: e.target.value });
          }}
          className="mt-1"
          type="number"
          placeholder="00 hr"
        />
        {job.errors?.overtime_per_month && (
          <span className="text-destructive">
            {job.errors.overtime_per_month}
          </span>
        )}
      </div>}
      <div className="mb-6">
        <p>Whether there is a trail period?</p>
        <RadioGroup
          value={job.trial_deadline ? "1" : "0"}
          onValueChange={(e) => setJob({ ...job, trial_deadline: e == "1" })}
          defaultValue={job.trial_deadline ? "1" : "0"}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="1" id="yes" />
            <Label htmlFor="yes">Yes</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="0" id="no" />
            <Label htmlFor="no">No</Label>
          </div>
        </RadioGroup>
        {job.errors?.trail_deadline && (
          <span className="text-destructive">{job.errors.trail_deadline}</span>
        )}
      </div>
      {job.trial_deadline && <div className="mb-6">
        <Label htmlFor="title">Explin trail period. *</Label>
        <JoditEditor
          ref={editor}
          value={job.trial_explain}
          config={config}
          onBlur={(newContent) => setJob({ ...job, trial_explain: newContent })}
          onChange={(newContent) =>
            setJob({ ...job, trial_explain: newContent })
          }
          className="mt-1"
        />
        {job.errors?.trail_explain && (
          <span className="text-destructive">{job.errors.trail_explain}</span>
        )}
      </div>}
      <div className="flex justify-between">
        <span
          onClick={() => setTab("2")}
          className="flex items-center gap-2 px-6 border rounded-full cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Previous</span>
        </span>
        <Button className="flex items-center gap-2 px-6 rounded-full">
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button>
      </div>
    </form>
  );
};

export default StepThree;
