import { Application } from "@/types";
import { useState } from "react";

import {
  User,
  BriefcaseBusiness,
  GraduationCap,
  IdCard,
  Star,
  Mail,
  MapPin,
  MapPinHouse,
  Calendar,
  Heart,
  Dna,
  Phone,
  Signpost,
  UserPlus,
  Download
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";

type Props = {
  application: Application;
};
const UserDetails = ({ application }: Props) => {
  const [tabs] = useState([
    {
      icon: <User />,
      name: "userinfo"
    },
    {
      icon: <BriefcaseBusiness />,
      name: "work"
    },
    {
      icon: <GraduationCap />,
      name: "education"
    },
    {
      icon: <IdCard />,
      name: "id"
    },
    {
      icon: <Star />,
      name: "star"
    }
  ]);
  return (
    <div className="flex bg-white h-[85vh] w-full rounded-xl dark:bg-slate-700">
      <ul className="relative h-full px-1 py-4 rounded-r-lg sm:p-4 rounded-e-lg bg-slate-100 dark:bg-slate-600">
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className="p-3 my-3 bg-white rounded-full cursor-pointer dark:bg-slate-500"
          >
            {tab.icon}
          </li>
        ))}
        <li className="absolute bottom-12">
          <Dialog>
            <DialogTrigger className="p-3 text-white rounded-full bg-primary">
              <BriefcaseBusiness />
            </DialogTrigger>
            <DialogContent>
              <DialogTitle></DialogTitle>
              <DialogHeader></DialogHeader>
              <DialogDescription></DialogDescription>
              <div>
                <div className="flex flex-col items-center my-4">
                  <img
                    src="/vite.svg"
                    className="rounded-lg w-[100px] h-[100px]"
                    alt=""
                  />
                  <h2 className="text-lg font-semibold">
                    {application.job.title}
                  </h2>
                  <p>Applied - {application.job.anyone}</p>
                  <p>Posted - {application.job.created_at?.slice(0, 10)}</p>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </li>
      </ul>
      <div className="flex-grow h-full pb-4 overflow-y-scroll hide-scroll">
        <section className="flex flex-col items-center py-8">
          <img
            src={application.cv_form?.image?.url}
            className="w-[180px] h-[180px] border rounded-lg object-cover"
            alt="User Profile"
          />
          <h1 className="mt-4 text-xl font-semibold capitalize">
            {application.user?.name}
          </h1>
          <h3 className="text-primary">{application?.cv_form?.occupation}</h3>
        </section>
        {application.cv_form && application.cv_form.name ? (
          <>
            <section className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <User strokeWidth={3} />
                <span>Profile</span>
              </h2>
              <div className="grid gap-6 p-3 py-6 md:grid-cols-2">
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Mail />
                    <span>Email</span>
                  </h3>
                  <p className="ml-7">{application.user?.email}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <MapPin />
                    <span>Permanenet Address</span>
                  </h3>
                  <p className="ml-7">{application.cv_form.email ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <MapPinHouse />
                    <span>Permanent Address</span>
                  </h3>
                  <p className="ml-7">{application.cv_form.address ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <UserPlus />
                    <span>Age</span>
                  </h3>
                  <p className="ml-7">{application.cv_form.age ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Calendar />
                    <span>Date of Birth</span>
                  </h3>
                  <p className="ml-7">{application.cv_form.dob ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Heart />
                    <span>Martial Status</span>
                  </h3>
                  <p className="ml-7">
                    {application.cv_form.martial_status?.name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Dna />
                    <span>Gender</span>
                  </h3>
                  <p className="ml-7">{application.cv_form.gender ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Phone />
                    <span>Phone Number</span>
                  </h3>
                  <p className="ml-7">{application.cv_form.phone_no ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Signpost />
                    <span>Postal Code</span>
                  </h3>
                  <p className="ml-7">
                    {application.cv_form.postal_code ?? "-"}
                  </p>
                </div>
              </div>
            </section>
            <section className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <BriefcaseBusiness strokeWidth={3} />
                <span>Experience</span>
              </h2>
              {application.cv_form.work_experiences.map((exp) => (
                <div key={exp.company_name} className="p-2 py-6 border-b">
                  <div className="justify-between sm:flex">
                    <div>
                      <h3 className="text-xl">{exp.company_name}</h3>
                      <h3 className="text-lg">{exp.position}</h3>
                    </div>
                    <p className="flex items-center gap-2 text-lg">
                      <Calendar />
                      <span>
                        {format(exp.start_date, "MMM,yyyy")} -{" "}
                        {format(exp.end_date, "MMM,yyyy")}
                      </span>
                    </p>
                  </div>
                  <p className="mt-4">{exp.why_quit}</p>
                </div>
              ))}
            </section>
            <section className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <GraduationCap strokeWidth={3} />
                <span>Education</span>
              </h2>
              {application.cv_form.schools.map((edu) => (
                <div key={edu.name} className="p-2 py-6 border-b">
                  <div className="justify-between sm:flex">
                    <div>
                      <h3 className="text-xl">
                        {edu.name}
                      </h3>
                      <h4>{edu.major}</h4>
                    </div>
                    <p className="flex items-center gap-2 text-lg">
                      <Calendar />
                      <span>{format(edu.from, "MMM,yyyy")} - {format(edu.to, "MMM,yyyy")}</span>
                    </p>
                  </div>
                  <p className="my-4">
                    {edu.school_status?.name}
                  </p>
                </div>
              ))}
            </section>
            <section className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <IdCard strokeWidth={3} />
                <span>Licence . Qualification</span>
              </h2>
              <div className="p-2 py-6 border-b">
                <div className="items-center justify-between sm:flex">
                  <h3 className="text-xl">Driving Licence</h3>
                  <p className="flex items-center gap-2 text-lg">
                    <Calendar />
                    <span>{application.cv_form?.driver_license_status?.name}</span>
                  </p>
                </div>
              </div>
                {
                  application.cv_form.certificates.map((certi) => (
                    <div key={certi.name} className="p-2 py-6 border-b">
                      <div className="items-center justify-between sm:flex">
                        <h3 className="text-xl">{certi.name}</h3>
                        <p className="flex items-center gap-2 text-lg">
                          <Calendar />
                          <span>{format(new Date(certi.year , certi.month , 1), "MMM,yyyy")}</span>
                        </p>
                      </div>
                    </div>    
                  ))
                }
            </section>
            <section className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <Star strokeWidth={3} />
                <span>Skills & Hobbies</span>
              </h2>
              <h3 className="mt-4 text-xl">Skills</h3>
              {
                application.cv_form.skills.map((skill) => (
                  <div key={skill.name} className="p-2 py-6 border-b">
                    <h4 className="text-lg">{skill.name}</h4>
                    <p className="my-4">{skill.description}</p>
                  </div>
                ))
              }
              <h3 className="mt-4 text-xl">Skills</h3>
              {
                application.cv_form.hobbies.map((hobby) => (
                  <div key={hobby} className="p-2 py-6 border-b">
                    <h4 className="text-lg"> • {hobby}</h4>
                  </div>
                ))
              }
            </section>
          </>
        ) : application.cv_file ? (
          <>
            <div className="flex justify-end">
              <Button asChild>
                <a className="flex items-center gap-2" target="_blank" href={application.cv_file.url} download={application.cv_file.url}>
                  <Download />
                  <span>Download cv file</span>
                </a>
              </Button>
            </div>
            <iframe key={application.cv_file.id} src={application.cv_file.url} className="w-full mb-4 lg:w-2/3 xl:w-1/2 mx-auto h-fit aspect-[9/16]" width="100px" height="100px"></iframe>
          </>
        ) : (
          <p className="py-4 text-center">No Cv form or file is provided.</p>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
