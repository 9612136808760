import { Job } from "@/types";
import { createContext, useReducer, useMemo, ReactNode, Dispatch } from "react";

type JobAction =
  | { type: "Add_FIELDS"; payload: Partial<Job> }
  | { type: "ERROR"; payload: { [key: string]: string } }
  | { type: "DELETE_JOB"; payload: undefined };

interface JobContextType {
  job: Job;
  dispatch: Dispatch<JobAction>;
}

export const JobContext = createContext<JobContextType | undefined>(undefined);

// Reducer function
const jobReducer = (state: Job, action: JobAction): Job => {
  switch (action.type) {
    case "Add_FIELDS":
      return {
        ...state,
        ...action.payload,
      };
    case "ERROR":
      return {
        ...state,
        errors: {
          ...action.payload,
          initial: "",
        },
      };
    case "DELETE_JOB":
      return {};
    default:
      return state;
  }
};

interface JobProviderProps {
  children: ReactNode;
}

// Provider component
export const JobProvider = ({ children }: JobProviderProps) => {
  let j = {
    id: "",
    title: "",
    company_id: "",
    agent_id: "",
    postal_code: "",
    state_id: 1,
    address: "",
    employment_type_id : 1,
    anyone: 1,
    male: 0,
    female: 0,
    min_age: null,
    max_age: null,
    employee_type: {
      id : 1,
      name : "Full Time"
    },
    sub_category_id: 0,
    category_id: 1,
    description: "",
    requirements: "",
    require_education: false,
    jp_language_require: false,
    pay_scale: "hourly",
    salary_cut_off: 0,
    salary_after_cut_off: "",
    explain_salary_cut_off: "",
    bonus: true,
    yearly_salary: 0,
    overtime: true,
    overtime_per_monthly: "",
    trial_deadline: true,
    trial_explain: "",
    housing_support: true,
    housing_explain: "",
    off_days_per_year: 1,
    off_days_explain: "",
    show_pay_by: "between",
    min_salary: 0,
    max_salary: 0,
    pr_point: 0,
    publish: true,
    job_expired_date: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    fixed_salary: 0,
    tax: 1,
    tax_explain: "",
    tax_left_explain: "",
    bonus_explain: "",
    working_hour: 0,
    working_hour_per: "monthly",
    overtime_per_month: 0,
    access_explain: "",
    foreign_apply: false,
    applied_users: [],
    errors: {
      "dummy" : "This is dummy field to satisfy ts."
    },
  };
  if (sessionStorage.getItem("job")) {
    j = JSON.parse(sessionStorage.getItem("job"));
    delete j.errors;
  }
  const [job, dispatch] = useReducer(jobReducer, j);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({ job, dispatch }), [job]);

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};
