/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import ApiService from "@/services/ApiService";
import { useToast } from "./use-toast";

const useQuery = (url: string, params?: object, dependencies?: unknown) => {
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { toast } = useToast();

  useEffect(() => {
    setLoading(true);
    setError(null);
    setData(null);
    setMeta(null);

    const fetchData = async () => {
      try {
        const response = await ApiService.get(url, { params: params });
        setData(response.data.data);
        setMeta(response.data.meta);
      } catch (err) {
        setError(err);
        toast({
          title: err?.response?.data?.message,
          description: err?.response?.data?.message,
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dependencies]);

  return { data, loading, error, meta };
};

export default useQuery;
