import { Button } from "@/components/ui/button";
import useQuery from "@/hooks/use-query";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { Application } from "@/types";

const ApplicationsView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {
    data: applications,
    loading,
    error,
    meta
  } = useQuery(
    "job-applications",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search
  );

  if (error) {
    return "error";
  }

  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };

  return !loading ? (
    <div>
      <div className="flex items-center">
        <h1 className="flex-grow text-xl font-semibold">
          Job Applications - ({applications.length})
        </h1>
      </div>
      <div>
        <div className="grid grid-cols-5 px-3 py-2 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
          <div>ID</div>
          <div>Name</div>
          <div>Job Title</div>
          <div>Applied Date</div>
          <div>Actions</div>
        </div>
        {applications.length ? (
          applications.map((application: Application) => (
            <div
              key={application.id}
              className="grid items-center grid-cols-5 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
            >
              <div>{application.id}</div>
              <div>{application.user ? application.user.name : ""}</div>
              <div>{application.job.title}</div>
              <div>{application.created_at?.slice(0 , 10)}</div>
              <div>
                <Button
                  asChild
                  className="border bg-background dark:bg-foreground border-background text-slate-800"
                >
                  <Link to={`/job-applications/${application.id}?tab=cv`}>
                    View Details
                  </Link>
                </Button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Data.</p>
        )}
      </div>

      <div className="flex justify-center gap-2 mt-6">
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page < 2}
          onClick={() => {
            paginate(meta.current_page - 1, meta.per_page);
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page >= meta.last_page}
          onClick={() => {
            paginate(meta.current_page + 1, meta.per_page);
          }}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : (
    <div>loading</div>
  );
};

export default ApplicationsView;
