import GridView from "@/components/jobs/GridView";
import ListView from "@/components/jobs/ListView";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useQuery from "@/hooks/use-query";
import {
  AlignJustify,
  ChevronLeft,
  ChevronRight,
  Edit,
  LayoutGrid,
} from "lucide-react";
import { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

type Layout = "grid" | "list";

const JobsView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [layout, setLayout] = useState<Layout>("list");

  const {
    data: jobs,
    loading,
    error,
    meta,
  } = useQuery(
    "jobs",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search,
  );

  if (error) {
    return "error";
  }

  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };

  return !loading ? (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="flex-grow text-xl font-semibold">Jobs List</h1>
        <div className="flex items-center gap-3">
          {layout == "grid" ? (
            <Button
              onClick={() => {
                setLayout("list");
              }}
              size="icon"
              className="hover:bg-secondary bg-secondary text-primary"
            >
              <LayoutGrid />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setLayout("grid");
              }}
              size="icon"
              className="hover:bg-secondary bg-secondary text-primary"
            >
              <AlignJustify />
            </Button>
          )}
          <Button
            size="icon"
            className="hover:bg-secondary bg-secondary text-primary"
          >
            <Edit />
          </Button>
          <Select defaultValue="last 30 days">
            <SelectTrigger className="w-[180px] hover:bg-secondary bg-secondary text-slate-800">
              <SelectValue placeholder="Theme" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="last 30 days">last 30 days</SelectItem>
              <SelectItem value="dark">Dark</SelectItem>
              <SelectItem value="system">System</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div>
        {layout == "grid" ? <GridView jobs={jobs} /> : <ListView jobs={jobs} />}
      </div>
      <div className="flex justify-center gap-2 mt-6">
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page < 2}
          onClick={() => {
            paginate(meta.current_page - 1, meta.per_page);
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page >= meta.last_page}
          onClick={() => {
            paginate(meta.current_page + 1, meta.per_page);
          }}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : (
    <div>loading</div>
  );
};

export default JobsView;
