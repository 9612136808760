import { Application } from "@/types";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { FormEvent, useState } from "react";
import { Button } from "../ui/button";
import { ArrowUp, Ellipsis, WorkflowIcon } from "lucide-react";

import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";

type Props = {
  application: Application;
};
const ProcessInfo = (props: Props) => {
  const { toast } = useToast();
  const [application, setApplication] = useState<Application>(
    props.application
  );
  const [loading , setLoading] = useState(false);
  const handleSubmit = (e : FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const obj = {
      status : application.status
    }
    ApiService.put(`job-applications/${application.id}/update-status` , obj).then((res) => {
      console.log(res)
      toast({
        title: res.data?.message,
        description: res.data?.message,
        variant: "success"
      });
      setLoading(false);
    }).catch((err) => {
      console.log(err)
      toast({
        title: err.response.data?.message,
        description: err.response.data?.message,
        variant: "destructive"
      });
      setLoading(false);
    })

  }
  return (
    <div>
      <div className="p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
        <h2 className="text-xl">Update Job Applied Status</h2>
        <form onSubmit={handleSubmit} className="flex items-end gap-2">
          <div className="flex-grow">
            <Label htmlFor="status">Status</Label>
            <Select
              value={application.status}
              onValueChange={(e) => {
                setApplication({ ...application, status: e });
              }}
            >
              <SelectTrigger className="w-full mt-1 ">
                <SelectValue placeholder="Application Status" />
              </SelectTrigger>
              <SelectContent defaultValue={application.status}>
                <SelectItem value="interviewing">Interviewing</SelectItem>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="document_screening">Document Screening</SelectItem>
                <SelectItem value="accepted">Accepted</SelectItem>
                <SelectItem value="declined">Declined</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button className="flex items-center gap-2">
            {loading ? <Ellipsis /> : <ArrowUp className="w-4 h-4" />}
            {loading ? <span>Updating</span> : <span>Update Status</span>}
          </Button>
        </form>
      </div>
      <div className="mt-6">
        <h2 className="mb-3 text-xl">Time Line</h2>
        <VerticalTimeline animate={false} layout={"1-column-left"}>
          {
            application.process_list ? application.process_list.map((process) => (
              <VerticalTimelineElement
                key={process.created_at}
                className="vertical-timeline-element--work"
                date={process.created_at?.slice(0,10)}
                iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                contentStyle={{ background: "#fff", color: "#303030" }}
                icon={<WorkflowIcon />}
              >
                <h3 className="vertical-timeline-element-title">{process.description}</h3>
              </VerticalTimelineElement>
            )) : (
              <p className="py-12 text-center">
                No ongoing process yet.
              </p>
            )
          }
          <VerticalTimelineElement
            iconStyle={{ background: "#fff", color: "#303030" }}
            icon={<WorkflowIcon />}
          />
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default ProcessInfo;
