import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import JoditEditor from "jodit-react";
import { useRef, useMemo } from "react";
import { ChevronLeft } from "lucide-react";
import { Job } from "@/types";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
};

const StepFive = ({ setTab, job, setJob, handleSubmit }: Props) => {
  const editor = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ];
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter text here...",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
    }),
    [],
  );
  return (
    <form
      onSubmit={(e) => handleSubmit(e, "2")}
      className="max-h-[80vh] overflow-y-auto"
    >
      <div className="mb-6">
        <p>Publish *</p>
        <RadioGroup
          value={job.publish ? "1" : "0"}
          onValueChange={(e) => setJob({ ...job, publish: e == "1" })}
          defaultValue={job.publish ? "1" : "0"}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="1" id="on" />
            <Label htmlFor="on">ON</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="0" id="off" />
            <Label htmlFor="off">OFF</Label>
          </div>
        </RadioGroup>
        {job.errors?.publish && (
          <span className="text-destructive">{job.errors.publish}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explin benefits.</Label>
        <JoditEditor
          ref={editor}
          value={job.description}
          config={config}
          onBlur={(newContent) => setJob({ ...job, requirements: newContent })}
          onChange={(newContent) => setJob({ ...job, requirements: newContent })}
          className="mt-1"
        />
        {job.errors?.description && (
          <span className="text-destructive">{job.errors.description}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Job apply process.</Label>
        <JoditEditor
          ref={editor}
          value={job.description}
          config={config}
          onBlur={(newContent) => setJob({ ...job, description: newContent })}
          onChange={(newContent) => setJob({ ...job, description: newContent })}
          className="mt-1"
        />
        {job.errors?.description && (
          <span className="text-destructive">{job.errors.description}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explin the advantages of the job.</Label>
        <JoditEditor
          ref={editor}
          value={job.description}
          config={config}
          onBlur={(newContent) => setJob({ ...job, description: newContent })}
          onChange={(newContent) => setJob({ ...job, description: newContent })}
          className="mt-1"
        />
        {job.errors?.description && (
          <span className="text-destructive">{job.errors.description}</span>
        )}
      </div>
      <div className="flex justify-between">
        <span
          onClick={() => setTab("4")}
          className="flex items-center gap-2 px-6 py-2 border rounded-full cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Previous</span>
        </span>
        {/* <Button className="flex items-center gap-2 px-6 rounded-full">
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button> */}
      </div>
    </form>
  );
};

export default StepFive;
