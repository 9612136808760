import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CvForm, User } from "@/types";
import { useState } from "react";
import { Button } from "../ui/button";
import FormBasics from "./FormBasics";
import FormExperiences from "./FormExperiences";
import FormEducation from "./FormEducation";
import FormSkills from "./FormSkills";
import FormCertificates from "./FormCertificates";
import {
  ArrowLeft,
  Edit,
  Ellipsis,
  Link as IconLink,
  Upload
} from "lucide-react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { Link, useNavigate } from "react-router-dom";
import FormImage from "./FormImage";

const CvForms = ({
  user,
  reload,
  id
}: {
  user: User;
  reload: () => void;
  id: number | string;
}) => {
  const redirectJob = window.sessionStorage.getItem("job");
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isCreating, setIsCreating] = useState(redirectJob ? true : false);
  const [tab, setTab] = useState("image");
  const [cv, setCv] = useState<CvForm>({
    name: user.name,
    image_id: 11,
    name_japan: "",
    label: "",
    occupation: "",
    gender: "male",
    age: 1,
    dob: user.dob,
    nationality_id: 1,
    martial_status_id: 2,
    phone_no: "",
    email: user.email,
    facebook_name: "",
    facebook_profile_link: "",
    postal_code: "",
    state_id: user.state?.id ? user.state.id : 1,
    address: user.address ? user.address : "",
    visa_status_id: 1,
    visa_deadline_date: "",
    arrival_date: "",
    japan_level_id: 1,
    driver_license_status_id: 1,
    other_personal_staff: "",
    work_experiences: [
      {
        company_name: "",
        position: "",
        start_date: "",
        end_date: "",
        why_quit: ""
      }
    ],
    hobbies: [""],
    schools: [
      {
        name: "",
        major: "",
        from: new Date().toString(),
        to: new Date().toString(),
        school_status_id: 1
      }
    ],
    certificates: [
      {
        name: "",
        year: 2024,
        month: 1,
        date: new Date()
      }
    ],
    skills: [
      {
        name: ""
      }
    ]
  });
  const [createLoading, setCreateLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const upladCv = () => {
    setCreateLoading(true);
    const obj = cv;
    ApiService.post(`agent-users/${user.id}/cv-forms`, obj)
      .then((res) => {
        setCreateLoading(false);
        setIsCreating(false);
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success"
        });
        if (redirectJob) {
          navigate(`/shared-jobs/${redirectJob}`);
        } else {
          setIsCreating(false);
          reload();
        }
      })
      .catch((err) => {
        setCreateLoading(false);
        setErrors(err.response?.data?.errors);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-semibold">Created Cv Forms</h1>
        <Button
          onClick={() => setIsCreating(true)}
        >
          Create Cv Form
        </Button>
      </div>
      {isCreating ? (
        <div className="p-2 bg-white rounded-lg dark:bg-slate-800">
          <div className="flex items-center gap-2">
            <Button
              onClick={() => setIsCreating(false)}
              size="icon"
              variant="ghost"
            >
              <ArrowLeft />
            </Button>
            <h1 className="text-2xl">Create Cv Form</h1>
          </div>
          <Tabs value={tab} defaultValue="account">
            <TabsList className="w-full bg-transparent">
              <TabsTrigger onClick={() => setTab("image")} value="image">
                Image
              </TabsTrigger>
              <TabsTrigger onClick={() => setTab("basics")} value="basics">
                Basics
              </TabsTrigger>
              <TabsTrigger onClick={() => setTab("exp")} value="exp">
                Experiences
              </TabsTrigger>
              <TabsTrigger onClick={() => setTab("edu")} value="edu">
                Education
              </TabsTrigger>
              <TabsTrigger
                onClick={() => setTab("certificates")}
                value="certificates"
              >
                Certificates
              </TabsTrigger>
              <TabsTrigger onClick={() => setTab("skills")} value="skills">
                Skills
              </TabsTrigger>
            </TabsList>
            <TabsContent value="image">
              <FormImage
                errors={errors}
                setTab={setTab}
                cv={cv}
                setCv={setCv}
              />
            </TabsContent>
            <TabsContent value="basics">
              <FormBasics
                errors={errors}
                setTab={setTab}
                cv={cv}
                setCv={setCv}
              />
            </TabsContent>
            <TabsContent value="exp">
              <FormExperiences
                errors={errors}
                setTab={setTab}
                cv={cv}
                setCv={setCv}
              />
            </TabsContent>
            <TabsContent value="edu">
              <FormEducation
                errors={errors}
                setTab={setTab}
                cv={cv}
                setCv={setCv}
              />
            </TabsContent>
            <TabsContent value="certificates">
              <FormCertificates
                errors={errors}
                setTab={setTab}
                cv={cv}
                setCv={setCv}
              />
            </TabsContent>
            <TabsContent value="skills">
              <FormSkills
                errors={errors}
                setTab={setTab}
                cv={cv}
                setCv={setCv}
              />
            </TabsContent>
          </Tabs>
          <div className="flex justify-center my-4">
            <Button
              onClick={upladCv}
              disabled={createLoading}
              className="flex items-center w-full gap-2 md:w-1/2"
            >
              {!createLoading ? <Upload /> : <Ellipsis />}
              {!createLoading ? (
                <span>Upload Cv Now</span>
              ) : (
                <span>Loading</span>
              )}
            </Button>
          </div>
        </div>
      ) : (
        <ul className="relative cursor-pointer grid grid-cols-1 md:grid-cols-2 mt-4 gap-4 min-h-[80vh]">
          {user.cv_forms?.map((cv) => (
            <li
              key={cv.id}
              className="p-3 overflow-x-scroll bg-white rounded-lg hide-scroll h-fit dark:bg-slate-600"
            >
              <div className="flex items-center justify-between">
                <h3 className="mb-2 text-lg font-semibold">{cv.label}</h3>
                <Button variant="secondary">
                  <Link to={`/cv-list/${id}/cv-forms/${cv.id}`}>
                    <Edit />
                  </Link>
                </Button>
              </div>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Occupation </span>
                <span> - {cv.occupation}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Name </span>
                <span> - {cv.name}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Name in Japan </span>
                <span> - {cv.name_japan}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Age </span>
                <span> - {cv.age}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Date of Birth </span>
                <span> - {cv.dob}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Gender </span>
                <span> - {cv.gender}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Email </span>
                <span> - {cv.email}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Phone Number </span>
                <span> - {cv.phone_no}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Postal Code </span>
                <span> - {cv.postal_code}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Address </span>
                <span> - {cv.address}</span>
              </p>
              <p className="grid grid-cols-2 gap-2 mt-1">
                <span>• Facebook Link </span>
                <a
                  target="_blank"
                  className="flex text-primary"
                  href={cv.facebook_profile_link}
                >
                  {" "}
                  - <IconLink className="ml-2" />
                </a>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CvForms;
