import { User } from "@/types";
import { Button } from "../ui/button";
import { useRef } from "react";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";

type Props = {
    user : User
}
const UserCv = ({user} : Props) => {
    const fileInput = useRef(null);
    const {toast} = useToast();
    const uploadCv = (e : React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (!file) {
            toast({
                title: "Error uploading cv.",
                description: "Please try again.",
                variant: "destructive"
              });      
        } else {
            const formData = new FormData();
            formData.set('file' , file);
            ApiService.post(`agent-users/${user.id}/upload` , formData , {'Content-Type' : 'multipart/form-data'}).then((res) => {
                toast({
                    title: res.data.message,
                    description: res.data.message,
                    variant: "success"
                  });
                window.location.reload();       
            }).catch((err) => {
                toast({
                    title: err.response.data?.message,
                    description: err.response.data?.errors?.file,
                    variant: "destructive"
                  });          
            })
        }
    }
    return ( 
        <div>
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl font-semibold lg:col-span-3 md:col-span-2 h-fit">Uploaded Cv Files</h1>
                <Button onClick={() => fileInput.current.click()}>Upload CV</Button>
                <input onChange={uploadCv} ref={fileInput} accept=".pdf" type="file" className="hidden" />
            </div>
            <div className="min-h-[85vh] md:grid-cols-2 grid lg:grid-cols-3 gap-4">
            {
                user.cv_files.map((cv) => (
                    <iframe key={cv.id} src={cv.url}  width="100%" height="600px" className="h-[500px] rounded-lg"></iframe>
                ))
            }
        </div>
        </div>
     );
}
 
export default UserCv;