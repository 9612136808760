import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import JoditEditor from "jodit-react";

import { useRef, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Job } from "@/types";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
};

const StepFour = ({ setTab, job, setJob, handleSubmit }: Props) => {
  const editor = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ];
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter text here...",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
    }),
    [],
  );

  return (
    <form
      onSubmit={(e) => handleSubmit(e, "5")}
      className="max-h-[80vh] overflow-y-auto"
    >
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Job Pr</h2>
        <p className="my-2">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          accusantium aliquam optio quo consequuntur deleniti totam{" "}
        </p>
      </div>
      <div className="mb-6">
        <p>Whether there is housing support? * </p>
        <RadioGroup
          value={job.housing_support ? "1" : "0"}
          onValueChange={(e) => setJob({ ...job, housing_support: e == "1" })}
          defaultValue={job.housing_support ? "1" : "0"}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="1" id="yes" />
            <Label htmlFor="yes">Yes</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="0" id="no" />
            <Label htmlFor="no">No</Label>
          </div>
        </RadioGroup>
        {job.errors?.housing_support && (
          <span className="text-destructive">{job.errors.housing_support}</span>
        )}
      </div>
      {job.housing_support && <div className="mb-6">
        <Label htmlFor="title">Explin the housing support.</Label>
        <JoditEditor
          ref={editor}
          value={job.housing_explain}
          config={config}
          onBlur={(newContent) =>
            setJob({ ...job, housing_explain: newContent })
          }
          onChange={(newContent) =>
            setJob({ ...job, housing_explain: newContent })
          }
          className="mt-1"
        />
        {job.errors?.housing_explain && (
          <span className="text-destructive">{job.errors.housing_explain}</span>
        )}
      </div>}
      <div className="mb-6">
        <Label htmlFor="postal_code">Off days per year *</Label>
        <Input
          value={job.off_days_per_year}
          onChange={(e) => {
            setJob({ ...job, off_days_per_year: Number(e.target.value) });
          }}
          className="mt-1"
          type="number"
          placeholder="00 hr"
        />
        {job.errors?.off_days_per_year && (
          <span className="text-destructive">
            {job.errors.off_days_per_year}
          </span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explin about off days. *</Label>
        <JoditEditor
          ref={editor}
          value={job.off_days_explain}
          config={config}
          onBlur={(newContent) =>
            setJob({ ...job, off_days_explain: newContent })
          }
          onChange={(newContent) =>
            setJob({ ...job, off_days_explain: newContent })
          }
          className="mt-1"
        />
        {job.errors?.off_days_explain && (
          <span className="text-destructive">
            {job.errors.off_days_explain}
          </span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explin the advantages of the job.</Label>
        <JoditEditor
          ref={editor}
          value={job.description}
          config={config}
          onBlur={(newContent) => setJob({ ...job, description: newContent })}
          onChange={(newContent) => setJob({ ...job, description: newContent })}
          className="mt-1"
        />
        {job.errors?.description && (
          <span className="text-destructive">{job.errors.description}</span>
        )}
      </div>
      <div className="flex justify-between">
        <span
          onClick={() => setTab("3")}
          className="flex items-center gap-2 px-6 border rounded-full cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Previous</span>
        </span>
        <Button className="flex items-center gap-2 px-6 rounded-full">
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button>
      </div>
    </form>
  );
};

export default StepFour;
