import { useState } from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import DatePicker from "../ui/date-picker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { format } from "date-fns";
import useQuery from "@/hooks/use-query";
import { User } from "@/types";

type Props = {
  user : User
};
const UserInfo = ({ user : u }: Props) => {
  const [user, setUser] = useState({ ...u });
  const [editing, setEditing] = useState(false);
  const { data: states, loading: statesLoading } = useQuery(`states`);
  const { data: nationalities, loading: nationalitiesLoading } = useQuery(`nationalities`);

  const cancelEdit = () => {
    setUser({ ...u });
    setEditing(false);
  };

  const emitDate = (value: Date) => {
    setUser(() => {
      return {
        ...user,
        dob: format(value, "yyyy-MM-dd")
      };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(user);
  };

  return (
    <div className="gap-8 p-1 bg-white rounded-lg md:flex dark:bg-slate-700 sm:p-4">
      <img
        src="/vite.svg"
        className="max-w-[240px] h-fit shadow-lg p-2 w-full mx-auto sm:w-1/5 rounded-full object-cover"
        alt=""
      />
      <form
        onSubmit={handleSubmit}
        className="grid flex-grow grid-cols-1 gap-4 h-fit md:grid-cols-2"
      >
        <div>
          <Label>Name</Label>
          <Input
            value={user.name}
            disabled={!editing}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          />
        </div>
        <div>
          <Label>Gender</Label>
          <Input
            value={user.gender}
            disabled={!editing}
            onChange={(e) => setUser({ ...user, gender: e.target.value })}
          />
        </div>
        <div>
          <Label>Date of Birth</Label>
          <br />
          <DatePicker
            disabled={!editing}
            date={new Date(user.dob ? user.dob : '2000-01-01')}
            emitDate={emitDate}
          />
        </div>
        <div className="mb-6">
          <Label htmlFor="state_id">State *</Label>
          {!statesLoading ? (
            <Select
              value={user.state_id?.toString()}
              disabled={!editing}
              onValueChange={(e) => {
                setUser({ ...user, state_id: Number(e) });
              }}
            >
              <SelectTrigger className="w-full mt-1 ">
                <SelectValue placeholder="Choose State">
                  {states?.find((s) => s.id == user.state_id)?.name}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {states?.map((state) => (
                  <SelectItem key={state.id} value={state.id}>
                    {state.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <div className="px-3 py-2 text-sm rounded bg-slate-200">Loading . . .</div>
          )}
        </div>
        <div className="mb-6">
          <Label htmlFor="nationality_id">Nationality</Label>
          {!nationalitiesLoading ? (
            <Select
              value={user.nationality_id?.toString()}
              disabled={!editing}
              onValueChange={(e) => {
                setUser({ ...user, nationality_id: Number(e) });
              }}
            >
              <SelectTrigger className="w-full mt-1 ">
                <SelectValue placeholder="Choose nationality">
                  {nationalities?.find((nation) => nation.id == user.nationality_id)?.name}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {nationalities?.map((nation) => (
                  <SelectItem key={nation.id} value={nation.id}>
                    {nation.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <div className="px-3 py-2 text-sm rounded bg-slate-200">Loading . . .</div>
          )}
        </div>
        <div>
          <Label>Visa Type</Label>
          <Input
            value={user.visa_type}
            disabled={!editing}
            onChange={(e) => setUser({ ...user, visa_type: e.target.value })}
          />
        </div>
        <div className="flex justify-end col-span-2">
          {editing ? (
            <div className="flex items-center gap-4">
              <span
                onClick={cancelEdit}
                className="px-4 py-2 rounded-lg cursor-pointer border-primary"
              >
                Cancel
              </span>
              <Button>Update</Button>
            </div>
          ) : (
            <span
              onClick={() => setEditing(true)}
              className="px-4 py-2 text-white rounded-lg cursor-pointer bg-primary"
            >
              Edit Profile
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
