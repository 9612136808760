import { CvForm } from "@/types";
import { Button } from "../ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../ui/select";
import useQuery from "@/hooks/use-query";
import { Textarea } from "../ui/textarea";
import SelectState from "./SelectState";
import { CalendarDatePicker } from "../ui/calendar-date-picker";
import { useState } from "react";
import { format } from "date-fns";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  cv: CvForm;
  setCv: React.Dispatch<React.SetStateAction<CvForm>>;
  errors: {
    [key: string]: string;
  };
};
const FormBasics = ({ setTab, cv, setCv, errors }: Props) => {
  const { data: states, loading: statesLoading } = useQuery(`states`);
  const { data: martialStatuses, loading: martialLoading } =
    useQuery(`martial-statuses`);
  const { data: visas, loading: visaLoading } = useQuery(`visa-statuses`);
  const { data: japanLevels, loading: levelsLoading } =
    useQuery(`japan-levels`);
  const { data: driverStatuses, loading: driverLoading } = useQuery(
    `driver-licensed-statuses`
  );
  const { data: nationalities, loading: nationalitiesLoading } =
    useQuery(`nationalities`);

  // Date picker needs refactoring, asking for an obj with from property, 
  // should just be primitive date obj or date string
  //formbasics - dob , arrival , deadline
  //appointments - option.date (popover in dialog not working)
  //formeducation - start_date , end_date
  const [dob , setDob] = useState({from : new Date()});
  const [arrival , setArrival] = useState({from : new Date()});
  const [deadline , setDeadline] = useState({from : new Date()});

  const stateSelected = (e: number) => {
    setCv({ ...cv, state_id: e });
  };

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      <h1 className="text-xl md:col-span-2">Personal Information</h1>
      <div>
        <Label>Label</Label>
        <Input
          value={cv.label}
          onChange={(e) => setCv({ ...cv, label: e.target.value })}
          className="mt-1"
          placeholder="UI/UX CV"
        />
        {errors.label ? (
          <p className="text-destructive">{errors.label}</p>
        ) : null}
      </div>
      <div>
        <Label>Occupation</Label>
        <Input
          value={cv.occupation}
          onChange={(e) => setCv({ ...cv, occupation: e.target.value })}
          className="mt-1"
          placeholder="UI/UX Developer"
        />
        {errors.occupation ? (
          <p className="text-destructive">{errors.occupation}</p>
        ) : null}
      </div>
      <div>
        <Label>Name</Label>
        <Input
          value={cv.name}
          onChange={(e) => setCv({ ...cv, name: e.target.value })}
          className="mt-1"
          placeholder="John Doe"
        />
        {errors.name ? <p className="text-destructive">{errors.name}</p> : null}
      </div>
      <div>
        <Label>Japanese Name</Label>
        <Input
          value={cv.name_japan}
          onChange={(e) => setCv({ ...cv, name_japan: e.target.value })}
          className="mt-1"
          placeholder="John Doe"
        />
        {errors.name_japan ? (
          <p className="text-destructive">{errors.name_japan}</p>
        ) : null}
      </div>
      <div>
        <Label>Gender</Label>
        <Select
          value={cv.gender}
          onValueChange={(e) => {
            setCv({ ...cv, gender: e });
          }}
        >
          <SelectTrigger className="w-full mt-1">
            <SelectValue placeholder="male" />
          </SelectTrigger>
          <SelectContent defaultValue="male">
            <SelectItem value="male">Male</SelectItem>
            <SelectItem value="female">Female</SelectItem>
          </SelectContent>
        </Select>
        {errors.gender ? (
          <p className="text-destructive">{errors.gender}</p>
        ) : null}
      </div>
      <div>
        <Label>Age</Label>
        <Input
          value={cv.age}
          onChange={(e) => setCv({ ...cv, age: Number(e.target.value) })}
          type="number"
          className="mt-1"
          placeholder="John Doe"
        />
        {errors.age ? <p className="text-destructive">{errors.age}</p> : null}
      </div>
      <div>
        <Label>Date of Birth</Label>
        {/* <input
          type="date"
          value={cv.dob}
          onChange={(e) => {
            console.log(e.target.value , typeof e.target.value)
            setCv({...cv , dob : e.target.value})
          }}
          className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
        /> */}
        <CalendarDatePicker
          date={dob}
          onDateSelect={({ from }) => {
            setDob({from})
            setCv({...cv , dob : format(from , 'dd-MM-yyyy')})
          }}
          variant="outline"
          numberOfMonths={1}
          className="flex justify-start w-full border-0 hover:bg-slate-100 dark:hover:bg-slate-300 hover:text-slate-700 bg-slate-100 dark:bg-slate-300 text-slate-800"
        />
        {errors.dob ? <p className="text-destructive">{errors.dob}</p> : null}
      </div>
      <div>
        <Label htmlFor="martial_status_id">Martial Status</Label>
        {!martialLoading ? (
          <Select
            value={cv.martial_status_id?.toString()}
            onValueChange={(e) => {
              setCv({ ...cv, martial_status_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose nationality">
                {
                  martialStatuses?.find(
                    (status) => status.id == cv.martial_status_id
                  )?.name
                }
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {martialStatuses?.map((status) => (
                <SelectItem key={status.id} value={status.id}>
                  {status.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2.5 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )}
        {errors.martial_status_id ? (
          <p className="text-destructive">{errors.martial_status_id}</p>
        ) : null}
      </div>
      <div>
        <Label>Email</Label>
        <Input
          value={cv.email}
          onChange={(e) => setCv({ ...cv, email: e.target.value })}
          className="mt-1"
          type="email"
          placeholder="John Doe"
        />
        {errors.email ? (
          <p className="text-destructive">{errors.email}</p>
        ) : null}
      </div>
      <div>
        <Label>Phone Number</Label>
        <Input
          value={cv.phone_no}
          onChange={(e) => setCv({ ...cv, phone_no: e.target.value })}
          className="mt-1"
          placeholder="123456"
        />
        {errors.phone_no ? (
          <p className="text-destructive">{errors.phone_no}</p>
        ) : null}
      </div>
      <div>
        <Label>Postal Code</Label>
        <Input
          value={cv.postal_code}
          onChange={(e) => setCv({ ...cv, postal_code: e.target.value })}
          className="mt-1"
          placeholder="1234567"
        />
        {errors.postal_code ? (
          <p className="text-destructive">{errors.postal_code}</p>
        ) : null}
      </div>
      <div>
        <Label htmlFor="state_id">State *</Label>
        {/* {!statesLoading ? (
          <Select
            value={cv.state_id?.toString()}
            onValueChange={(e) => {
              setCv({ ...cv, state_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose State">
                {states?.find((s) => s.id == cv.state_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {states?.map((s) => (
                <SelectItem key={s.id} value={s.id}>
                  {s.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )} */}
        {!statesLoading ? (
          <SelectState
            state_id={cv.state_id}
            states={states}
            onValueChange={stateSelected}
          />
        ) : (
          <div className="px-3 py-2.5 mt-1 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )}
        {errors.state_id ? (
          <p className="text-destructive">{errors.state_id}</p>
        ) : null}
      </div>
      <div>
        <Label htmlFor="nationality_id">Nationality</Label>
        {!nationalitiesLoading ? (
          <Select
            value={cv.nationality_id?.toString()}
            onValueChange={(e) => {
              setCv({ ...cv, nationality_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose nationality">
                {
                  nationalities?.find(
                    (nation) => nation.id == cv.nationality_id
                  )?.name
                }
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {nationalities?.map((nation) => (
                <SelectItem key={nation.id} value={nation.id}>
                  {nation.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )}
        {errors.nationality_id ? (
          <p className="text-destructive">{errors.nationality_id}</p>
        ) : null}
      </div>
      <div>
        <Label>Address</Label>
        <Textarea
          value={cv.address}
          onChange={(e) => setCv({ ...cv, address: e.target.value })}
          className="mt-1"
          placeholder=". . ."
          rows={1}
        />
        {errors.address ? (
          <p className="text-destructive">{errors.address}</p>
        ) : null}
      </div>
      <div>
        <Label>Facebook Name</Label>
        <Input
          value={cv.facebook_name}
          onChange={(e) => setCv({ ...cv, facebook_name: e.target.value })}
          className="mt-1"
          placeholder="John Doe"
        />
        {errors.facebook_name ? (
          <p className="text-destructive">{errors.facebook_name}</p>
        ) : null}
      </div>
      <div>
        <Label>Facebook Profile Link</Label>
        <Input
          value={cv.facebook_profile_link}
          onChange={(e) =>
            setCv({ ...cv, facebook_profile_link: e.target.value })
          }
          className="mt-1"
          placeholder="https://www.facebook.com/user"
        />
        {errors.facebook_profile_link ? (
          <p className="text-destructive">{errors.facebook_profile_link}</p>
        ) : null}
      </div>
      <div>
        <Label htmlFor="visa_status_id">Visa Status</Label>
        {!visaLoading ? (
          <Select
            value={cv.visa_status_id?.toString()}
            onValueChange={(e) => {
              setCv({ ...cv, visa_status_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose nationality">
                {visas?.find((visa) => visa.id == cv.visa_status_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {visas?.map((visa) => (
                <SelectItem key={visa.id} value={visa.id}>
                  {visa.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )}
        {errors.visa_status_id ? (
          <p className="text-destructive">{errors.visa_status_id}</p>
        ) : null}
      </div>
      <div>
        <Label>Visa Deadline Date</Label>
        {/* <input
          type="date"
          value={cv.visa_deadline_date}
          onChange={(e) => setCv({ ...cv, visa_deadline_date: e.target.value })}
          className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
        /> */}
        <CalendarDatePicker
          date={deadline}
          onDateSelect={({ from }) => {
            setDeadline({from})
            setCv({...cv , visa_deadline_date : format(from , 'dd-MM-yyyy')})
          }}
          variant="outline"
          numberOfMonths={1}
          className="flex justify-start w-full border-0 hover:bg-slate-100 dark:hover:bg-slate-300 hover:text-slate-700 bg-slate-100 dark:bg-slate-300 text-slate-800"
        />
        {errors.visa_deadline_date ? (
          <p className="text-destructive">{errors.visa_deadline_date}</p>
        ) : null}
      </div>
      <div>
        <Label>Arrival Date</Label>
        {/* <input
          type="date"
          value={cv.arrival_date}
          onChange={(e) => setCv({ ...cv, arrival_date: e.target.value })}
          className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
        /> */}
        <CalendarDatePicker
          date={arrival}
          onDateSelect={({ from }) => {
            setArrival({from})
            setCv({...cv , arrival_date : format(from , 'dd-MM-yyyy')})
          }}
          variant="outline"
          numberOfMonths={1}
          className="flex justify-start w-full border-0 hover:bg-slate-100 dark:hover:bg-slate-300 hover:text-slate-700 bg-slate-100 dark:bg-slate-300 text-slate-800"
        />
        {errors.arrival_date ? (
          <p className="text-destructive">{errors.arrival_date}</p>
        ) : null}
      </div>
      <div>
        <Label htmlFor="japan_level_id">Japanese Level</Label>
        {!levelsLoading ? (
          <Select
            value={cv.japan_level_id?.toString()}
            onValueChange={(e) => {
              setCv({ ...cv, japan_level_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose nationality">
                {
                  japanLevels?.find((level) => level.id == cv.japan_level_id)
                    ?.name
                }
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {japanLevels?.map((level) => (
                <SelectItem key={level.id} value={level.id}>
                  {level.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )}
        {errors.japan_level_id ? (
          <p className="text-destructive">{errors.japan_level_id}</p>
        ) : null}
      </div>
      <div>
        <Label htmlFor="driver_license_status_id">Driver License Status</Label>
        {!driverLoading ? (
          <Select
            value={cv.driver_license_status_id?.toString()}
            onValueChange={(e) => {
              setCv({ ...cv, driver_license_status_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose nationality">
                {
                  driverStatuses?.find(
                    (status) => status.id == cv.driver_license_status_id
                  )?.name
                }
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {driverStatuses?.map((status) => (
                <SelectItem key={status.id} value={status.id}>
                  {status.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 text-sm rounded bg-slate-200">
            Loading . . .
          </div>
        )}
        {errors.driver_license_status_id ? (
          <p className="text-destructive">{errors.driver_license_status_id}</p>
        ) : null}
      </div>
      <div className="md:col-span-2">
        <Label>Other Personal Staff</Label>
        <Textarea
          value={cv.other_personal_staff}
          onChange={(e) =>
            setCv({ ...cv, other_personal_staff: e.target.value })
          }
          className="mt-1"
          placeholder=". . ."
          rows={4}
        />
        {errors.other_personal_staff ? (
          <p className="text-destructive">{errors.other_personal_staff}</p>
        ) : null}
      </div>

      <div className="flex justify-between mt-8 md:col-span-2">
        <Button
          onClick={() => setTab("image")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <ChevronLeft />
          <span>Previous</span>
        </Button>
        <Button
          onClick={() => setTab("exp")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default FormBasics;
