import { Button } from "@/components/ui/button";
import { Job } from "@/types";
import {
  Bookmark,
  ChevronUp,
  Eye,
  Share,
  SquareActivity,
  Users,
  UsersRound
} from "lucide-react";
import parse from "html-react-parser";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";

type Props = {
  job: Job;
};

const JobInfo = ({ job }: Props) => {
  const {toast} = useToast();
  const shareJob = () => {
    ApiService.post(`jobs/${job.id}/share`, {})
      .then((res) => {
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success"
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };
  return (
    <div>
      <div className="flex p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
        <div className="grid grid-cols-4 gap-x-3 gap-y-1">
          <img
            className="w-[100px] row-span-3 h-[100px] rounded"
            src="/logo.svg"
            alt=""
          />
          <h3 className="col-span-3 text-xl font-semibold">{job.title}</h3>
          <p className="col-span-3 font-semibold">{job.agent?.name}</p>
          <p className="col-span-3">Agent ID - {job.agent_id}</p>
        </div>
        <div className="flex justify-end flex-grow">
          <Button disabled={job.has_shared} onClick={shareJob} size="lg" className="rounded-full">
            <Share />
            <span className="ml-4">Share Job</span>
          </Button>
        </div>
      </div>
      <div className="grid-cols-4 gap-4 md:grid">
        <div className="col-span-3 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
          <h3 className="text-lg">Postal Code</h3>
          <p className="mb-4">• {job.postal_code}</p>

          <h3 className="text-lg">State</h3>
          <p className="mb-4">• {job.state?.name}</p>

          <h3 className="text-lg">Number of People to Hire</h3>
          <p className="mb-4">• {job.anyone}</p>

          <h3 className="text-lg">Employee Type</h3>
          <p className="mb-4">• {job.employee_type?.name}</p>

          <h3 className="text-lg">Job Category</h3>
          <p className="mb-4">• {job.category?.name}</p>

          <h3 className="text-lg">Job Subcategory</h3>
          <p className="mb-4">• {job.sub_category?.name}</p>

          <h3 className="text-lg">Basic Salary</h3>
          <p className="mb-4">• {job.min_salary}</p>

          <h3 className="text-lg">Yearly Salary</h3>
          <p className="mb-4">• {job.yearly_salary}</p>

          <h3 className="text-lg">Job Expired Date</h3>
          <p className="mb-4">• {job.job_expired_date}</p>

          <h3 className="text-lg">Description</h3>
          <div className="mb-4 paragraph">
            {job.description ? parse(job.description) : ""}
          </div>

          <h3 className="text-lg">Requirements</h3>
          <div className="mb-4 paragraph">
            {job.requirements ? parse(job.requirements) : "-"}
          </div>

          <h3 className="text-lg">Off Days</h3>
          <p>• {job.off_days_per_year}</p>
          <div className="mb-4 paragraph">
            {job.off_days_explain ? parse(job.off_days_explain) : ""}
          </div>

          <h3 className="text-lg">Salary Tax</h3>
          <p>• {job.tax}</p>
          <div className="mb-4 paragraph">
            {job.tax_explain ? parse(job.tax_explain) : ""}
          </div>

          <h3 className="text-lg">Bonus</h3>
          <p>• {job.bonus ? "Yes" : "No"}</p>
          <div className="mb-4 paragraph">
            {job.bonus_explain ? parse(job.bonus_explain) : ""}
          </div>

          <h3 className="text-lg">Housing Support</h3>
          <p>• {job.housing_support ? "Yes" : "No"}</p>
          <div className="mb-4 paragraph">
            {job.housing_explain ? parse(job.housing_explain) : ""}
          </div>

          <h3 className="text-lg">Advantages of the Job</h3>
          <div className="flex items-center mb-4 paragraph">
            {job.description ? parse(job.description) : ""}
          </div>

          <h3 className="text-lg">Benefits</h3>
          <div className="flex items-center mb-4 paragraph">
            {job.description ? parse(job.description) : ""}
          </div>
        </div>
        <div className="col-span-1">
          <div className="p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
            <h3 className="text-lg">Job Status</h3>
            <p className="flex items-center px-3 py-2 mt-1 border rounded-lg border-primary">
              <span className="flex-grow text-primary">Interviewing </span>
              <ChevronUp className="w-4 h-4 text-primary" />
            </p>
          </div>
          <div className="grid grid-cols-4 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700 gap-x-4 gap-y-1">
            <span className="flex items-center justify-center row-span-4 my-auto text-primary">
              <Users className="w-[60px] bg-blue-100 text-blue-700 rounded-full p-3 h-[60px]" />
            </span>
            <h3 className="col-span-3">Applications Sent</h3>
            <p className="col-span-3 text-2xl font-bold ">12 </p>
          </div>
          <div className="grid grid-cols-4 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700 gap-x-4 gap-y-1">
            <span className="flex items-center justify-center row-span-4 my-auto text-primary">
              <SquareActivity className="w-[60px] bg-red-100 text-red-700 rounded-full p-3 h-[60px]" />
            </span>
            <h3 className="col-span-3">Post Views</h3>
            <p className="col-span-3 text-2xl font-bold ">100 </p>
          </div>
          <div className="grid grid-cols-4 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700 gap-x-4 gap-y-1">
            <span className="flex items-center justify-center row-span-4 my-auto text-primary">
              <Bookmark className="w-[60px] bg-teal-100 text-teal-700 rounded-full p-3 h-[60px]" />
            </span>
            <h3 className="col-span-3">Posts Saved</h3>
            <p className="col-span-3 text-2xl font-bold ">20 </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobInfo;
