import JobDetail from "@/components/jobs/create/JobDetail";
import Steps from "@/components/jobs/create/Steps";
import { JobProvider } from "@/contexts/JobContext";

const CreateJob = () => {
  return (
    <div className="grid-cols-4 gap-3 lg:grid">
      <JobProvider>
        <div className="col-span-3 p-3 bg-white rounded-lg dark:bg-slate-700">
          <Steps />
        </div>
        <div className="p-3 mt-6 bg-white rounded-lg lg:mt-0 dark:bg-slate-700">
          <JobDetail />
        </div>
      </JobProvider>
    </div>
  );
};

export default CreateJob;
