// import { User } from "@/types";
import useQuery from "@/hooks/use-query";
import { Button } from "../ui/button";
import { ChevronRight } from "lucide-react";

// const users: Array<User> = [
//   {
//     id: 1,
//     name: "Peter Parker",
//     dob: "2002-02-01",
//     gender: "Male",
//     email: "peter@gmail.com",
//     state: {
//       name: "Yangon"
//     }
//   },
//   {
//     id: 2,
//     name: "Peter Parker",
//     email: "peter@gmail.com",
//     dob: "2002-02-01",
//     gender: "Male",
//     state: {
//       name: "Yangon"
//     }
//   },
//   {
//     id: 3,
//     name: "Peter Parker",
//     email: "peter@gmail.com",
//     dob: "2002-02-01",
//     gender: "Male",
//     state: {
//       name: "Yangon"
//     }
//   }
// ];

type Props = {
  selectUser: (user : {[key : string] : unknown}) => void;
};

const List = ({ selectUser }: Props) => {
  const { data: users, loading, error } = useQuery("agent-users");

  if (error) {
    return "error";
  }
  return !loading ? (
    <ul className="max-h-[600px] overflow-y-scroll hide-scroll">
      <li className="grid grid-cols-5 gap-2 px-2 py-3 rounded-lg bg-background">
        <p className="text-center">Name</p>
        <p className="text-center">Birthday</p>
        <p className="text-center">Gender</p>
        <p className="text-center">State</p>
        <p className="text-center">Actions</p>
      </li>
      {users.map((user) => (
        <li
          key={user.id}
          className="grid items-center grid-cols-5 gap-2 p-2 my-2 rounded-lg dark:bg-slate-500 bg-slate-200"
        >
          <p className="text-center">{user.name}</p>
          <p className="text-center">{user.dob}</p>
          <p className="text-center">{user.gender}</p>
          <p className="text-center">{user.state?.name}</p>
          <p className="flex justify-center">
            <Button
              onClick={() => selectUser(user)}
              className="flex items-center gap-2 bg-tint-background"
            >
              <span>Cv List</span>
              <ChevronRight />
            </Button>
          </p>
        </li>
      ))}
    </ul>
  ) : (
    <p className="py-8 text-center">Loading . . .</p>
  );
};

export default List;
