import { CvForm } from "@/types";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ChevronLeft, Plus, Trash } from "lucide-react";
import { Textarea } from "../ui/textarea";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  cv: CvForm;
  setCv: React.Dispatch<React.SetStateAction<CvForm>>;
  errors: {
    [key: string]: string;
  };
};

const FormSkills = ({ setTab, cv, setCv, errors }: Props) => {
  const setArrayData = (index: number, key: string, value: string) => {
    const skillsArray = cv.skills.map((skill, i) => {
      if (i == index) {
        skill[key] = value;
      }
      return skill;
    });
    setCv({ ...cv, skills: skillsArray });
  };
  const addMoreskill = () => {
    const obj = {
      name: ""
    };
    setCv({ ...cv, skills: [...cv.skills, obj] });
  };

  const removeskill = (index: number) => {
    const skillsArray = cv.skills.filter((_, i) => i !== index);
    setCv({ ...cv, skills: skillsArray });
  };


  const setHobbiesArray = (index: number, value: string) => {
    const hobbiesArray = cv.hobbies.map((hobby, i) => {
      if (i == index) {
        hobby = value;
      }
      return hobby;
    });
    setCv({ ...cv, hobbies : hobbiesArray });
  };
  const addMoreHobby = () => {
    setCv({ ...cv, hobbies: [...cv.hobbies , ""] });
  };

  const removeHobby = (index: number) => {
    const hobbiesArray = cv.hobbies.filter((_, i) => i !== index);
    setCv({ ...cv, hobbies: hobbiesArray });
  };
  return (
    <div>
      <h1 className="text-xl">Skills & Hobbies</h1>
      <div className="mb-8">
        <h2 className="mt-4 text-lg">Skills</h2>
        <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
          {errors["skills.0.name"] ? (
            <p className="text-destructive">• {errors["skills.0.name"]}</p>
          ) : null}
        </div>
        {cv.skills?.map((skill, i) => (
          <div
            key={i}
            className="grid grid-cols-1 gap-2 p-2 mt-2 border rounded-lg md:grid-cols-2 dark:border-slate-500"
          >
            <div className="flex items-center justify-between gap-4 md:col-span-2">
              <h2 className="text-lg">• Skill {i + 1}</h2>
              <Button
                onClick={() => removeskill(i)}
                size="icon"
                variant="secondary"
              >
                <Trash />
              </Button>
            </div>
            <div>
              <Label>Name</Label>
              <Input
                value={cv.skills[i]?.name}
                onChange={(e) => setArrayData(i, "name", e.target.value)}
                className="flex-grow mt-1"
                placeholder="skill name"
              />
            </div>
            <div>
              <Label>Description</Label>
              <Textarea
                value={cv.skills[i]?.description}
                onChange={(e) => setArrayData(i, "description", e.target.value)}
                placeholder=". . ."
                className="mt-1"
                rows={1}
              />
            </div>
          </div>
        ))}
        <div className="flex justify-center mt-4">
          <Button
            onClick={addMoreskill}
            className="flex items-center gap-1"
            variant="secondary"
          >
            <Plus />
            <span>Add More Skill</span>
          </Button>
        </div>
      </div>
      <div className="mb-8">
      <h2 className="mt-4 text-lg">Hobbies</h2>
        <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
          {errors['hobbies.0'] ? <p className="text-destructive">• {errors['hobbies.0']}</p> : null}
        </div>
        {cv.hobbies?.map((hobby, i) => (
          <div
            key={i}
            className="p-2 mt-2 border rounded-lg dark:border-slate-500"
          >
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center flex-grow gap-2">
                <Label className="md:w-[200px] w-[150px]">
                  {i + 1}. Hobby Name
                </Label>
                -
                <Input
                  value={cv.hobbies[i]}
                  onChange={(e) => setHobbiesArray(i, e.target.value)}
                  className="flex-grow mt-1"
                  placeholder="hobby name"
                />
              </div>
              <Button
                onClick={() => removeHobby(i)}
                size="icon"
                variant="secondary"
              >
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="flex justify-center mt-4">
          <Button
            onClick={addMoreHobby}
            className="flex items-center gap-1"
            variant="secondary"
          >
            <Plus />
            <span>Add More Hobby</span>
          </Button>
        </div>
        <div className="flex justify-between mt-8 md:col-span-2">
          <Button
            onClick={() => setTab("certificates")}
            className="flex items-center gap-1 bg-transparent rounded-full"
            variant="outline"
          >
            <ChevronLeft />
            <span>Previous</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormSkills;
