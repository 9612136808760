import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import JoditEditor from "jodit-react";

import { useRef, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Job } from "@/types";
import useQuery from "@/hooks/use-query";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
};

const StepTwo = ({ setTab, job, setJob, handleSubmit }: Props) => {
  const {data : employeeTypes , loading : empLoading} = useQuery('employment-types');
  const editor = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ];
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter text here...",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
    }),
    [],
  );
  return (
    <form
      onSubmit={(e) => handleSubmit(e, "3")}
      className="max-h-[80vh] overflow-y-auto"
    >
      <div className="mb-6">
        <Label htmlFor="state_id">Employee Type *</Label>
        {!empLoading ? (
          <Select
            value={job.employment_type_id.toString()}
            onValueChange={(e) => {
              setJob({ ...job, employment_type_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose category">
                {employeeTypes?.find((type) => type.id == job.employment_type_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {employeeTypes?.map((type) => (
                <SelectItem key={type.id} value={type.id}>
                  {type.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 rounded bg-slate-200">Loading . . .</div>
        )}
        {job.errors?.employment_type_id && (
          <span className="text-destructive">{job.errors.employment_type_id}</span>
        )}
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Pay Scale</h2>
        <p className="my-2">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          accusantium aliquam optio quo consequuntur deleniti totam{" "}
        </p>
      </div>
      <div className="grid-cols-3 gap-3 mb-6 sm:grid">
        <div>
          <Label htmlFor="state_id">Show Pay By</Label>
          <Select
            value={job.show_pay_by}
            defaultValue={job.show_pay_by}
            onValueChange={(e) => {
              setJob({ ...job, show_pay_by: e });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="between" />
            </SelectTrigger>
            <SelectContent defaultValue="between">
              <SelectItem value="between">Between</SelectItem>
              <SelectItem value="min">Min</SelectItem>
              <SelectItem value="fixed">Fixed</SelectItem>
            </SelectContent>
          </Select>
          {job.errors?.show_pay_by && (
            <span className="text-destructive">{job.errors.show_pay_by}</span>
          )}
        </div>
        {
          job.show_pay_by == 'fixed' ? (
            <div className="col-span-2">
              <Label htmlFor="fixed_salary">Exact Amount </Label>
              <Input
                value={job.fixed_salary.toString()}
                onChange={(e) => {
                  setJob({ ...job, fixed_salary : Number(e.target.value) });
                }}
                className="mt-1"
                type="number"
                placeholder="000.000 $"
              />
            </div>
          ) : job.show_pay_by == 'min' ? (
            <div className="col-span-2">
              <Label htmlFor="min_salary">Minimum Salary </Label>
              <Input
                value={job.min_salary.toString()}
                onChange={(e) => {
                  setJob({ ...job, min_salary : Number(e.target.value) });
                }}
                className="mt-1"
                type="number"
                placeholder="000.000 $"
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 col-span-2 gap-2">
              <div>
                <Label htmlFor="min_salary">Minimum Salary </Label>
                <Input
                  value={job.min_salary.toString()}
                  onChange={(e) => {
                    setJob({ ...job, min_salary : Number(e.target.value) });
                  }}
                  className="mt-1"
                  type="number"
                  placeholder="000.000 $"
                />
              </div>
              <div>
                <Label htmlFor="max_salary">Minimum Salary </Label>
                <Input
                  value={job.max_salary.toString()}
                  onChange={(e) => {
                    setJob({ ...job, max_salary : Number(e.target.value) });
                  }}
                  className="mt-1"
                  type="number"
                  placeholder="000.000 $"
                />
              </div>
            </div>
          )
        }
        <div className="col-span-3">
          <Label htmlFor="state_id">Rate *</Label>
          <Select
            value={job.pay_scale}
            onValueChange={(e) => {
              setJob({ ...job, pay_scale: e });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Hourly" />
            </SelectTrigger>
            <SelectContent defaultValue="hourly">
              <SelectItem value="hourly">Hourly</SelectItem>
              <SelectItem value="daily">Daily</SelectItem>
              <SelectItem value="monthly">Monthly</SelectItem>
            </SelectContent>
          </Select>
          {job.errors?.pay_scale && (
            <span className="text-destructive">{job.errors.pay_scale}</span>
          )}
        </div>
      </div>
      <div className="mb-6">
        <Label htmlFor="title">How much salary tax will be added?</Label>
        <Input
          value={job.tax}
          onChange={(e) => setJob({ ...job, tax: e.target.value })}
          type="number"
          accept="0-9"
          className="mt-1"
        />
        {job.errors?.tax && (
          <span className="text-destructive">{job.errors.tax}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explain how much is left after taxes.</Label>
        <JoditEditor
          ref={editor}
          value={job.tax_left_explain}
          config={config}
          onBlur={(newContent) =>
            setJob({ ...job, tax_left_explain: newContent })
          }
          onChange={(newContent) =>
            setJob({ ...job, tax_left_explain: newContent })
          }
          className="mt-1"
        />
        {job.errors?.tax_left_explain && (
          <span className="text-destructive">
            {job.errors.tax_left_explain}
          </span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explain what the cut taxes are.</Label>
        <JoditEditor
          ref={editor}
          value={job.tax_explain}
          config={config}
          onBlur={(newContent) => setJob({ ...job, tax_explain: newContent })}
          onChange={(newContent) => setJob({ ...job, tax_explain: newContent })}
          className="mt-1"
        />
        {job.errors?.tax_explain && (
          <span className="text-destructive">{job.errors.tax_explain}</span>
        )}
      </div>
      <div className="mb-6">
        <p>Bonus * </p>
        <RadioGroup
          value={job.bonus ? "1" : "0"}
          onValueChange={(e) => setJob({ ...job, bonus: e == "1" })}
          defaultValue={job.bonus ? "1" : "0"}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="1" id="yes" />
            <Label htmlFor="yes">Yes</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="0" id="no" />
            <Label htmlFor="no">No</Label>
          </div>
        </RadioGroup>
        {job.errors?.bonus && (
          <span className="text-destructive">{job.errors.bonus}</span>
        )}
      </div>
      {job.bonus && <div className="mb-6 transition-all">
        <Label htmlFor="title">Bonus Explain. *</Label>
        <JoditEditor
          ref={editor}
          value={job.bonus_explain}
          config={config}
          onBlur={(newContent) => setJob({ ...job, bonus_explain: newContent })}
          onChange={(newContent) =>
            setJob({ ...job, bonus_explain: newContent })
          }
          className="mt-1"
        />
        {job.errors?.bonus_explain && (
          <span className="text-destructive">{job.errors.bonus_explain}</span>
        )}
      </div>}
      <div className="mb-6 transition-all">
        <Label htmlFor="postal_code">Yearly Salary *</Label>
        <Input
          value={job.yearly_salary}
          onChange={(e) => {
            setJob({ ...job, yearly_salary: e.target.value });
          }}
          className="mt-1"
          type="number"
          placeholder="postal code"
        />
        {job.errors?.yearly_salary && (
          <span className="text-destructive">{job.errors.yearly_salary}</span>
        )}
      </div>

      <div className="flex justify-between">
        <span
          onClick={() => setTab("1")}
          className="flex items-center gap-2 px-6 border rounded-full cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Previous</span>
        </span>
        <Button className="flex items-center gap-2 px-6 rounded-full">
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button>
      </div>
    </form>
  );
};

export default StepTwo;
