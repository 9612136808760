import AppliedList from "@/components/jobs/details/Appliedlist";
import JobInfo from "@/components/jobs/details/JobInfo";
import { Button } from "@/components/ui/button";
import useQuery from "@/hooks/use-query";
import { Job } from "@/types";
import { ChevronLeft, ReceiptText, Users } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const JobDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [search , setSearch] = useSearchParams();
  const id = params.id ? params.id : "";
  const [tab, setTab] = useState<string>(search.get('tab') ?? "info");
  const { data: job, loading, error } : {data : Job , error : unknown , loading : boolean} = useQuery(`jobs/${id}`);
  useEffect(() => {
    setSearch({tab : tab} , {replace : true});
  } , [tab , setSearch])
  if (error) {
    return "error";
  }
  return !loading ? (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <Button size="icon" onClick={() => navigate(-1)} className="rounded-full">
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => setTab("info")}
          variant={tab == "info" ? "default" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <ReceiptText className="w-4 h-4" />
          <span>Job Info</span>
        </Button>
        <Button
          onClick={() => setTab("list")}
          variant={tab == "list" ? "default" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <Users className="w-4 h-4" />
          <span>Applied List</span>
        </Button>
      </div>
      {tab == "info" ? <JobInfo job={job} /> : <AppliedList job={job} />}
    </div>
  ) : (
    <div>loading</div>
  );
};

export default JobDetails;
