import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Job } from "@/types";
import { Link } from "react-router-dom";
import { useMemo, useRef, useState } from "react";
import useQuery from "@/hooks/use-query";
import JoditEditor from "jodit-react";

type Props = {
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
};

const StepOne = ({ job, setJob, handleSubmit }: Props) => {
  const { data: states, loading: statesLoading } = useQuery(`states`);
  const { data: categories, loading: categoriesLoading } =
    useQuery(`job-categories`);
  const [subCategories, setSubCategories] = useState([]);
  const editor = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ];
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter text here...",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
    }),
    [],
  );

  return (
    <form onSubmit={(e) => handleSubmit(e, "2")}>
      <div className="mb-4">
        <Label htmlFor="title">Job Title *</Label>
        <Input
          value={job.title}
          onChange={(e) => {
            setJob({ ...job, title: e.target.value });
          }}
          className="mt-1"
          type="text"
          placeholder="title"
        />
        {job.errors?.title && (
          <span className="text-destructive">{job.errors.title}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="description">Job Description *</Label>
        <JoditEditor
          ref={editor}
          value={job.description}
          config={config}
          onBlur={(newContent) => setJob({ ...job, description: newContent })}
          onChange={(newContent) => setJob({ ...job, description: newContent })}
          className="mt-1"
        />
        {job.errors?.description && (
          <span className="text-destructive">{job.errors.description}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="requirements">Job Requirements.</Label>
        <JoditEditor
          ref={editor}
          value={job.requirements}
          config={config}
          onBlur={(newContent) => setJob({ ...job, requirements: newContent })}
          onChange={(newContent) => setJob({ ...job, requirements: newContent })}
          className="mt-1"
        />
        {job.errors?.requirements && <span className="text-destructive">{job.errors.requirements}</span> }
      </div>
      <div className="mb-6">
        <Label htmlFor="postal_code">Postal Code</Label>
        <Input
          value={job.postal_code}
          onChange={(e) => {
            setJob({ ...job, postal_code: e.target.value });
          }}
          className="mt-1"
          type="text"
          placeholder="postal code"
        />
        {job.errors?.postal_code && (
          <span className="text-destructive">{job.errors.postal_code}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="state_id">State *</Label>
        {!statesLoading ? (
          <Select
            value={job.state_id.toString()}
            onValueChange={(e) => {
              setJob({ ...job, state_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose category">
                {states?.find((s) => s.id == job.state_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {states?.map((state) => (
                <SelectItem key={state.id} value={state.id}>
                  {state.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 rounded bg-slate-200">Loading . . .</div>
        )}
        {job.errors?.state_id && (
          <span className="text-destructive">{job.errors.state_id}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="address">Job Address *</Label>
        <Textarea
          value={job.address}
          onChange={(e) => {
            setJob({ ...job, address: e.target.value });
          }}
          className="w-full mt-1 "
          placeholder="address"
        />
        {job.errors?.address && (
          <span className="text-destructive">{job.errors.address}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="number_of_hire">
          Number of people to hire on this job *
        </Label>
        <Input
          value={job.anyone}
          onChange={(e) => {
            setJob({ ...job, anyone: Number(e.target.value) });
          }}
          className="mt-1"
          type="number"
          placeholder="number of people to hire"
        />
        {job.errors?.anyone && (
          <span className="text-destructive">{job.errors.anyone}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="category">Job category</Label>
        {!categoriesLoading ? (
          <Select
            value={job.category_id.toString()}
            onValueChange={(e) => {
              setJob({ ...job, category_id: Number(e) });
              setSubCategories(() => {
                const cat = categories?.find((c) => c.id == e);
                if (cat) {
                  return cat.children;
                } else {
                  return [];
                }
              });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose category">
                {categories?.find((c) => c.id == job.category_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {categories.map((category) => (
                <SelectItem key={category.id} value={category.id}>
                  {category.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 rounded bg-slate-200">Loading . . .</div>
        )}
        {job.errors?.category_id && (
          <span className="text-destructive">{job.errors.category_id}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="sub_category">Job sub category *</Label>
        <Select
          value={job.sub_category_id.toString()}
          onValueChange={(e) => {
            setJob({ ...job, sub_category_id: Number(e) });
          }}
        >
          <SelectTrigger className="w-full mt-1 ">
            <SelectValue placeholder="Choose category">
              {subCategories.find((c) => c.id == job.sub_category_id)?.name}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {subCategories.map((category) => (
              <SelectItem key={category.id} value={category.id}>
                {category.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {job.errors?.sub_category_id && (
          <span className="text-destructive">{job.errors.sub_category_id}</span>
        )}
      </div>
      <div className="flex justify-between">
        <Button variant="ghost" className="border rounded-full">
          <Link
            to="/jobs/create/initial-information"
            className="flex items-center gap-2 px-6"
          >
            <ChevronLeft className="w-5 h-5" />
            <span>Previous</span>
          </Link>
        </Button>
        <Button className="flex items-center gap-2 px-6 rounded-full">
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button>
      </div>
    </form>
  );
};

export default StepOne;
